import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { GET_ADDITIONAL_FURNITURE_DEFAULT_DETAILS_ACTION_TYPE, headers, getDefaultFurnitureAgencyDetailsUrl } from "../../../../../utils/constants";

export const getDefaultFurnitureAgencyDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        const res = await axiosGet(getDefaultFurnitureAgencyDetailsUrl, params, headers)
        dispatch({
            type: GET_ADDITIONAL_FURNITURE_DEFAULT_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
