import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, exhDescriptionActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { getReservedPriorityNoDetails_action, insertReservedPriorityNo_action, deleteReservedPriorityNo_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/reservedPriorityNo_action';

class ReservedPriorityNo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            ExihibitionID: '',
            ReservedPriorityNoList: [],
            DescriptionList: [],
            tempDescriptionList: [],
            NoteTypeList: [],
            tempNoteTypeList: [],
        }
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhDescriptionActionId, '', '', '', '')

        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getReservedPriorityNoDetails()
        }, 200);
    }

    getFillList(value, userID, keyword, keyvalue) {
        // this.setState({ isLoading: true })
        this.props
            .fill_action(value, (userID == '' || userID == null) ? null : userID, keyword != '' ? keyword : null, keyvalue != '' ? keyvalue : null)
            .then((data) => {
                if (data.error != 1) {
                    if (value == exhDescriptionActionId) {
                        this.setState({
                            DescriptionList: data.data,
                            tempDescriptionList: data.data,
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    getReservedPriorityNoDetails() {
        const { ExihibitionID } = this.state
        this.setState({ isLoading: true })
        this.props.getReservedPriorityNoDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("1314", data.data)
                    if (data.data != '') {
                        data.data.map((item, i) => {
                            item.id = i + 1
                            item.Is_view = true
                        })
                        this.setState({ ReservedPriorityNoList: data.data })
                    } else {
                        this.setState({
                            ReservedPriorityNoList: [{ PriorityNo: '', Is_view: false }]
                        })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateReservedPriorityNoDetails() {
        const { ExihibitionID, ReservedPriorityNoList } = this.state
        this.setState({ isLoading: true })
        const array = []
        ReservedPriorityNoList.map((item) => {
            if (!item.Is_view) {
                array.push(item)
            }
        })
        this.props.insertReservedPriorityNo_action(ExihibitionID, array)
            .then((data) => {
                if (data.error != 1) {
                    this.getReservedPriorityNoDetails()
                    toastSuccess("data add Successfuly!")
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    addReservedPriorityNoList(i) {
        const { ReservedPriorityNoList } = this.state
        ReservedPriorityNoList.splice(
            i + 1, 0, {
            id: ReservedPriorityNoList.length + 1,
            PriorityNo: '',
            Is_view: false
        }
        )
        this.setState({ ReservedPriorityNoList: ReservedPriorityNoList })
    }

    deleteReservedPriorityNoList(db_id, id, flag) {

        if (flag) {
            const confirmed = window.confirm(
                "Do you really want to delete this record?"
            );

            if (confirmed) {
                this.setState({ isLoading: true })
                this.props
                    .deleteReservedPriorityNo_action(db_id)
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            this.getReservedPriorityNoDetails()
                            toast.success("Deleted SuccessFully!", {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toast.error(data.data, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        } else {
            const { ReservedPriorityNoList } = this.state
            const filterArray = ReservedPriorityNoList.filter(item => item.id != id)
            filterArray.map((item, i) => {
                item.id = i + 1
            })
            this.setState({ ReservedPriorityNoList: filterArray })
        }

    }

    onReservedPriorityNoInput(event, i) {
        const { ReservedPriorityNoList } = this.state
        ReservedPriorityNoList.map((data, index) => {
            if (i == index) {
                if (event != '') {
                    data.PriorityNo = event
                } else {
                    data.PriorityNo = ''
                }
            }
        })
        // const checkValue = this.DuplicatesRowsCheck(ReservedPriorityNoList, ['PriorityNo'])
        // if (checkValue) {
        //     toastError('PriorityNo already exist')
        // }
        this.setState({ ReservedPriorityNoList: ReservedPriorityNoList })
    }

    DuplicatesRowsCheck(data, keys) {
        const seen = new Set();

        for (const item of data) {
            const identifier = keys.map((key) => item[key]).join('|');
            if (seen.has(identifier)) {
                return true; // Duplicate found
            }
            seen.add(identifier); // Mark as seen
        }

        return false; // No duplicates found
    }

    record(ReservedPriorityNoList) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 w-50">
                <thead>
                    <tr style={{ border: '1px solid #ccc' }}>
                        <th className="text-left" style={{ width: '70%' }}>Reserved Priority No</th>
                        <th className="text-center" style={{ width: '5%' }} >Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ReservedPriorityNoList != '' && ReservedPriorityNoList != null ? (
                            ReservedPriorityNoList.map((data, i) => (
                                <tr key={i}>
                                    {!data.Is_view ?
                                        <td >
                                            <input className="form-control"
                                                type="text"
                                                value={data.PriorityNo}
                                                maxLength={4}
                                                placeholder="eg. 1,2,3,4"
                                                onChange={(e) => this.onReservedPriorityNoInput(e.target.value.replace(/\D/, ''), i)}
                                            />
                                        </td> :
                                        <td>{data.PriorityNo}</td>
                                    }
                                    <td className="text-center">
                                        {(ReservedPriorityNoList.length == i + 1) && <button
                                            style={{ marginRight: '8px' }}
                                            title="Add Note"
                                            class="btn btn-sm p-0 "
                                            onClick={() => this.addReservedPriorityNoList(i)}
                                        >
                                            <i class="fa fa-plus action_btn_table mr-2"></i>
                                        </button>
                                        }

                                        <button
                                            style={{ marginRight: '8px' }}
                                            title="Delete Note"
                                            class="btn btn-sm p-0"
                                            onClick={() => this.deleteReservedPriorityNoList(data.ID, data.id, data.Is_view)}
                                        >
                                            <i class="fa fa-trash action_btn_table p-0"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (<tr><td colSpan={6} className='text-center'>Record not found</td></tr>)
                    }
                </tbody>
            </table>)
    }


    render() {
        const { isLoading, ReservedPriorityNoList, currentPage } = this.state

        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = ReservedPriorityNoList.slice(indexOfFirstRecord, indexOfLastRecord)
        const nPages = Math.ceil(ReservedPriorityNoList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">
                        <div className="row" style={{ width: '97%', margin: 'auto' }}>
                            <div className="asc" style={{ padding: '20px 0' }}>
                                <div className="table-responsive">
                                    {this.record(ReservedPriorityNoList)}
                                </div>

                                <button type="button" class="btn btn-primary rounded-0 mt-2" style={{ float: 'left' }}
                                    onClick={() => this.insertUpdateReservedPriorityNoDetails()}>
                                    add
                                </button>
                            </div>
                            {/* <div className="mt-2 float-left">
                                {ReservedPriorityNoList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem} /> : <></>}

                            </div> */}
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, getReservedPriorityNoDetails_action, insertReservedPriorityNo_action, deleteReservedPriorityNo_action })(ReservedPriorityNo));