import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, exhDescriptionActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { insertUpdateExhAdditionalFurniture_action, getExhAdditionalFurnitureDetails_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/exh_additionalFurniture_action';

class Exh_AdditionalFurniture extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            ExihibitionID: '',
            exhAdditionalFurnitureList: [],
            DescriptionList: [],
            tempDescriptionList: [],
            AdditionalFurnitureTypeList: [],
            tempAdditionalFurnitureTypeList: [],
        }
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhDescriptionActionId, '', '', '', '')

        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getExhAdditionalFurnitureDetails()
        }, 200);
    }

    getFillList(value, userID, keyword, keyvalue) {
        // this.setState({ isLoading: true })
        this.props
            .fill_action(value, (userID == '' || userID == null) ? null : userID, keyword != '' ? keyword : null, keyvalue != '' ? keyvalue : null)
            .then((data) => {
                if (data.error != 1) {
                    if (value == exhDescriptionActionId) {
                        this.setState({
                            DescriptionList: data.data,
                            tempDescriptionList: data.data,
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    getExhAdditionalFurnitureDetails() {
        const { ExihibitionID } = this.state
        this.setState({ isLoading: true })
        this.props.getExhAdditionalFurnitureDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("1314", data.data)
                    if (data.data != '') {
                        data.data.map((item, i) => {
                            item.id = i + 1
                        })
                        this.setState({ exhAdditionalFurnitureList: data.data })
                    } else {
                        this.setState({
                            exhAdditionalFurnitureList: [
                                {
                                    CompanyName: null,
                                    PersonInchargeName: null,
                                    MobileNo: null,
                                    Email: null,
                                    Designation: null,
                                    id: 1
                                }
                            ]
                        })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateExhAdditionalFurnitureDetails() {
        const { ExihibitionID, exhAdditionalFurnitureList } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateExhAdditionalFurniture_action(ExihibitionID, exhAdditionalFurnitureList)
            .then((data) => {
                if (data.error != 1) {
                    this.getExhAdditionalFurnitureDetails()
                    toastSuccess("data updated Successfuly!")
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    addExhAdditionalFurnitureList(i) {
        const { exhAdditionalFurnitureList } = this.state
        exhAdditionalFurnitureList.splice(
            i + 1, 0, {
            id: exhAdditionalFurnitureList.length + 1,
            CompanyName: '',
            PersonInchargeName: '',
            MobileNo: '',
            Email: '',
            Designation: '',
        }
        )
        this.setState({ exhAdditionalFurnitureList: exhAdditionalFurnitureList })
    }

    checkForDuplicates(arr, keys) {
        const seen = new Set();
        for (const obj of arr) {
            const uniqueKey = keys.map(key => obj[key]).join('|');
            if (seen.has(uniqueKey)) {
                return true; // Duplicate found
            }
            seen.add(uniqueKey);
        }
        return false; // No duplicates found
    }


    deleteExhAdditionalFurnitureList(id) {
        const { exhAdditionalFurnitureList } = this.state
        const filterArray = exhAdditionalFurnitureList.filter(item => item.id != id)
        filterArray.map((item, i) => {
            item.id = i + 1
        })
        this.setState({ exhAdditionalFurnitureList: filterArray })
    }

    onInputChange(e, i, param) {
        const { exhAdditionalFurnitureList } = this.state
        const event = e.target.value
        exhAdditionalFurnitureList.map((data, index) => {
            if (i === index) {
                if (param == 'CompanyName') {
                    data.CompanyName = event
                } else if (param == 'PersonInchargeName') {
                    data.PersonInchargeName = event
                } else if (param == 'MobileNo') {
                    data.MobileNo = event
                } else if (param == 'Email') {
                    data.Email = event
                } else if (param == 'Designation') {
                    data.Designation = event
                }
            }
        })
        this.setState({ exhAdditionalFurnitureList: exhAdditionalFurnitureList })
    }


    record(exhAdditionalFurnitureList) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr style={{ border: '1px solid #ccc' }}>
                        <th className="text-center">Action</th>
                        <th className="text-left">Company Name</th>
                        <th className="text-left">PersonIncharge Name</th>
                        <th className="text-left">MobileNo</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">Designation</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        exhAdditionalFurnitureList != '' && exhAdditionalFurnitureList != null ? (
                            exhAdditionalFurnitureList.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center">
                                        <button
                                            title="Add AdditionalFurniture"
                                            class="btn btn-sm padding_0"
                                            onClick={() => this.addExhAdditionalFurnitureList(i)}
                                        >
                                            <i class="fa fa-plus action_btn_table"></i>
                                        </button>
                                        {this.state.exhAdditionalFurnitureList.length != 1 &&
                                            <button
                                                title="Delete AdditionalFurniture"
                                                class="btn btn-sm padding_0"
                                                onClick={() => this.deleteExhAdditionalFurnitureList(data.id)}
                                            >
                                                <i class="fa fa-trash action_btn_table"></i>
                                            </button>
                                        }
                                    </td>
                                    <td >
                                        <input className="form-control"
                                            style={{ width: '500px' }}
                                            type="text"
                                            value={data.CompanyName}
                                            onChange={(e) => this.onInputChange(e, i, 'CompanyName')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '500px' }}
                                            type="text"
                                            value={data.PersonInchargeName}
                                            onChange={(e) => this.onInputChange(e, i, 'PersonInchargeName')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '300px' }}
                                            type="text"
                                            value={data.MobileNo}
                                            onChange={(e) => this.onInputChange(e, i, 'MobileNo')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '300px' }}
                                            type="text"
                                            value={data.Email}
                                            onChange={(e) => this.onInputChange(e, i, 'Email')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '300px' }}
                                            type="text"
                                            value={data.Designation}
                                            onChange={(e) => this.onInputChange(e, i, 'Designation')}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (<tr><td colSpan={6} className='text-center'>Record not found</td></tr>)
                    }
                </tbody>
            </table>
        )
    }


    render() {
        const { isLoading, exhAdditionalFurnitureList, currentPage } = this.state

        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = exhAdditionalFurnitureList.slice(indexOfFirstRecord, indexOfLastRecord)
        const nPages = Math.ceil(exhAdditionalFurnitureList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">
                        <div className="row" style={{ margin: 'auto' }}>
                            <div className="table-responsive mt-2">
                                {this.record(exhAdditionalFurnitureList)}
                            </div>
                            <div class="col-md-12 mt-2">
                                <button type="button" class="btn btn-primary rounded-0" style={{ float: 'right' }}
                                    onClick={() => this.insertUpdateExhAdditionalFurnitureDetails()}>
                                    Save
                                </button>
                            </div>
                            {/* <div className="mt-2 float-left">
                                {exhAdditionalFurnitureList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem} /> : <></>}

                            </div> */}
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, insertUpdateExhAdditionalFurniture_action, getExhAdditionalFurnitureDetails_action })(Exh_AdditionalFurniture));