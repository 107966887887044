import { GET_ADDITIONAL_FURNITURE_DEFAULT_DETAILS_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function additionalFurnitureReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_ADDITIONAL_FURNITURE_DEFAULT_DETAILS_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default additionalFurnitureReducer;