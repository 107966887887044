import { Component, forwardRef } from "react";
import Header from "../../../common_components/Header";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../utils/pagination";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { receipt_list_search_action, shareEmail_action, getEmailData_action, getReceiptPdf_action, receiptShare_action }
  from "../../../actions/admin_module_action/registration_receipt_actions/receipt_action";
import { downloadReport, toastError, toastSuccess } from "../../../utils/constants";
import AdminHeader from "../../../common_components/admin_module_header";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Workbook from 'react-excel-workbook'
import Modal from "react-modal";
import Footerwithdr from '../../../common_components/Footerwithdr';
import HeaderWithLogo from "../../../common_components/header_with_logo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";


const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
  <input
    value={value}
    className="form-control"
    onClick={onClick}
    onChange={onChange}
    disabled={disabled}
    ref={ref}
  ></input>
));


class ReceiptList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      exhibitor: "",
      recptDtFrom: "",
      recptDtTo: "",
      recptNo: "",
      currentPage: 1,
      toEmail: '',
      ccEmail: '',
      attachedment: [],
      fileList: FileList | null,
      isOpen: false,
      recptList: [],
      excelList: [],
      isAllChecked: false,
      isOpen: false,
      taxableAmount: 0,
      totalReceivedAmt: 0,
      totalSD: 0,
      loginAccesslist: [],
      // isRightToAdd: false,
      // isRightToEdit: false,
      // isRightToView: false,

      //Error Msg
      exhibitorErr: "",
      recptDtFromErr: "",
      recptDtToErr: "",
      recptNoErr: "",
      checkBoxFlag: true
    }
    this.searchReceipt = this.searchReceipt.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.InputValidate = this.InputValidate.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleAllSelected = this.handleAllSelected.bind(this)
    this.onCreateExcel = this.onCreateExcel.bind(this)
    this.handleListClick = this.handleListClick.bind(this)
    this.handleView = this.handleView.bind(this)
    this.handleShare = this.handleShare.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleNewReceipt = this.handleNewReceipt.bind(this)
    this.calculateTotals = this.calculateTotals.bind(this)
    this.handleRemoveFile = this.handleRemoveFile.bind(this)
    this.getEmailData = this.getEmailData.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.onReceiptDownload = this.onReceiptDownload.bind(this)
    this.getWriteAccess = this.getWriteAccess.bind(this)
    this.getDownloadAccess = this.getDownloadAccess.bind(this)
    this.getSendAccess = this.getSendAccess.bind(this)
    this.getUpdateAccess = this.getUpdateAccess.bind(this)
  }

  componentDidMount() {
    this.searchReceipt()
    console.log("session storage loginDataSS", JSON.parse(sessionStorage.getItem('loginDataSS')))

    this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
    // this.setState({
    //   recptDtFrom: sessionStorage.getItem('ExhibitionFromDateSS'),
    //   recptDtTo: sessionStorage.getItem('ExhibitionToDateSS')
    // })
    // this.getEmailData()
    // this.setState({
    //   isRightToAdd: JSON.parse(sessionStorage.getItem('DummyLoginData')) != null &&
    //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'] != null &&
    //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'].find(obj => obj.ModuleId == 1 && obj.ModuleName == "Receipt").isAdd,
    //   isRightToEdit: JSON.parse(sessionStorage.getItem('DummyLoginData')) != null &&
    //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'] != null &&
    //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'].find(obj => obj.ModuleId == 1 && obj.ModuleName == "Receipt").isUpdate,
    //   isRightToView: JSON.parse(sessionStorage.getItem('DummyLoginData')) != null &&
    //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'] != null &&
    //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'].find(obj => obj.ModuleId == 1 && obj.ModuleName == "Receipt").isView,
    // })
    // const rights = JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'].find(obj => obj.ModuleId == 1 && obj.ModuleName == "Receipt")
    // console.log("zzzzzzzzzzzzzzzzzz", rights)
  }

  // get attachedment data
  getEmailData() {
    const { isLoading, exhibitor, recptDtFrom, recptDtTo, recptNo } = this.state

    this.setState({ isLoading: true })
    this.props
      .getEmailData_action(exhibitor, recptDtFrom, recptDtTo, recptNo)
      .then((data) => {
        if (data.error != 1) {
          this.setState({
            // recptList: data.data,
          })
          setTimeout(() => {
            this.setState({ isLoading: false })
          }, 500);
        }
        else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // Search Receipt 
  searchReceipt() {
    const { isLoading, exhibitor, recptDtFrom, recptDtTo, recptNo, currentPage } = this.state
    // Input Validate before search
    // this.InputValidate()

    // if (exhibitor !== "" && recptDtFrom !== "" && recptDtTo !== "" && recptNo !== "") {
    this.setState({ isLoading: true })
    this.props
      .receipt_list_search_action(exhibitor, recptDtFrom, recptDtTo, recptNo)
      .then((data) => {
        if (data.error != 1) {
          this.setState({
            recptList: data.data,
          })
          setTimeout(() => {
            console.log("aaaaaaaaaaaaa", data.data);
            this.updateItem(currentPage)
            this.setState({ isLoading: false })
          }, 500);
        }
        else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  //For Calculation All total Amounts in table 
  calculateTotals(list) {

    const { recptList, currentPage } = this.state


    let TaxableAmount = 0;
    let toatalSD = 0
    let totalCgst = 0;
    let totalIgst = 0;
    let totalSgst = 0;
    let TotalReceivedAmt = 0;

    console.log("recptList", recptList)

    list.length > 0 && list.forEach((data) => {
      console.log("00000", data.TaxableAmount)

      TaxableAmount += Number(data.TaxableAmount);
      toatalSD += Number(data.SecurityDeposite);
      totalCgst += Number(data.CGST);
      totalIgst += Number(data.IGST);
      totalSgst += Number(data.SGST);
      TotalReceivedAmt += Number(data.Amount)
      console.log("00000", TaxableAmount)

    });

    this.setState({
      totalReceivedAmt: TotalReceivedAmt.toFixed(2),
      taxableAmount: TaxableAmount.toFixed(2),
      totalSD: toatalSD.toFixed(2)
    })
    return {
      TaxableAmount,
      totalCgst,
      totalIgst,
      totalSgst,
      TotalReceivedAmt
    };
  }


  handleAllSelected(e) {
    const { recptList, isAllChecked } = this.state
    recptList.map(data => {
      if (e.target.checked) {
        data.is_checked = 1
        this.setState({ excelList: recptList, })
      } else {
        data.is_checked = 0
        this.setState({ excelList: [], })

      }
    })
    this.setState({ recptList: recptList, isAllChecked: !isAllChecked })

    const checkboxSelected = recptList.every(item => item.is_checked == 1)
    if (checkboxSelected) {
      this.setState({ checkBoxFlag: false })
    } else {
      this.setState({ checkBoxFlag: true })
    }
  }

  // Update Current Page
  updateItem(item) {
    const { recptList } = this.state
    this.setState({ currentPage: item });
    console.log("xyxyxyxyyxyxyyyxyx", item)
    const recordsPerPage = 10;
    const indexOfLastRecord = item * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
    const currentRecords = recptList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(recptList.length / recordsPerPage)
    this.calculateTotals(currentRecords)
  }

  clearValue() {
    this.setState({
      exhibitor: "",
      recptDtFrom: "",
      recptDtTo: "",
      recptNo: "",
    })
    setTimeout(() => {
      this.searchReceipt()
    }, 500);
  }

  //Input Fields Validation
  InputValidate() {
    const { exhibitor, recptDtFrom, recptDtTo, recptNo } = this.state
    if (exhibitor == "") {
      this.setState({ exhibitorErr: 'Please Enter Exhibitor Name' })
    }
    if (recptDtFrom == "") {
      this.setState({ recptDtFromErr: 'Please Enter Receipt Start Date' })
    }
    if (recptDtTo == "") {
      this.setState({ recptDtToErr: 'Please Enter Receipt End Date' })
    }
    if (recptNo == "") {
      this.setState({ recptNoErr: 'Please Enter Receipt No' })
    }
  }

  handleKeyDown(e) {
    if (e.code == 'Enter') {
      this.searchReceipt()
    }
  }

  // to create excel file
  onCreateExcel() {
    const { excelList } = this.state
    var list = [...excelList]
    list.length > 0 && list.map(data => {
      delete data.BaseAmount
    })
    console.log("excelList", excelList)
    var columnArray = [],
      rowData = [];
    // for column data 
    list.map((data, index) => {
      if (index == 0) {
        Object.keys(data).map((key, index) => {
          columnArray.push(key)
        })
      }
    })

    // for row data
    list.map((data, index) => {
      rowData.push(data)
    })

    // console.log("rowData", rowData)
    // this.setState({
    //     columnArrayData: columnArray,
    //     rowData: rowData
    // })

    // { this.renderList() }
    console.log(rowData, columnArray, list)
    return (
      <div className="row text-center" >
        {excelList.length > 0 ? <Workbook filename="RceiptList.xlsx" style={{}} element={
          <button type="button" className="btn ad-fil-btn m-0"
            disabled={!this.getWriteAccess()}
          >
            Excel
          </button>
        }>
          <Workbook.Sheet data={rowData} name="Sheet A">
            {columnArray.map((value, index) =>
              <Workbook.Column label={value} value={value} />
            )
            }
          </Workbook.Sheet>
        </Workbook> : <button type="button" className="btn ad-fil-btn m-0" disabled={true}>
          Excel
        </button>}
      </div>
    );
  }

  onShare(receiptId) {
    const { recptList } = this.state
    const receiptIdArray = []
    console.log("receiptIdArray receiptId ", receiptId)
    recptList.map((data) => {
      if (data.is_checked === 1) {
        receiptIdArray.push(
          {
            "ReceiptID": data.ReceiptID
          }
        )
      }
    })

    const singleReceiptIdArray = []
    singleReceiptIdArray.push(
      {
        "ReceiptID": receiptId
      }
    )

    console.log("receiptIdArray", receiptIdArray)

    this.setState({ isLoading: true })
    this.props
      .receiptShare_action(receiptId != undefined ? singleReceiptIdArray : receiptIdArray)
      .then((data) => {
        if (data.error != 1) {
          console.log("receipt share !!!!!!!!!!!!", data.data);
          this.setState({ isLoading: false })
          toastSuccess("Receipt share successfully!", {
            theme: 'colored',
            autoClose: 500,
            hideProgressBar: true,
          })
        }
        else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleListClick(e, id) {
    const { excelList, recptList, isAllChecked } = this.state
    var list = []
    recptList.map((data, index) => {
      if (data.ReceiptID === id) {
        if (e.target.checked) {
          data.is_checked = 1
          excelList.push(data)
        } else {
          data.is_checked = 0
          list = excelList.filter((data) => data.ReceiptID != id)
          this.setState({ excelList: list, isAllChecked: false })
        }
      }
    })

    this.setState({ recptList: recptList })
    const checkboxSelected = recptList.some(item => item.is_checked == 1)
    if (checkboxSelected) {
      this.setState({ checkBoxFlag: false })
    } else {
      this.setState({ checkBoxFlag: true })
    }

    const allSelected = recptList.every(item => item.is_checked == 1)

    if (allSelected) {
      this.setState({ isAllChecked: true })
    } else {
      this.setState({ isAllChecked: false })
    }

  }

  handleView(id) {
    this.props.navigate('/receiptentry', { state: { is_view: true, receiptId: id } })
  }

  handleUpdate(id) {
    this.props.navigate('/receiptentry', { state: { is_update: true, receiptId: id } })
  }

  handleNewReceipt() {
    this.props.navigate('/receiptentry', { state: { is_add: true, From: "ReceiptList" } })
  }

  handleRemoveFile(id) {
    const { ccEmail, toEmail, attachedment, fileList } = this.state
    //   const files = fileList ? [...fileList] : [];
    // this.setState({fileList : files.filter((file, i) => id != i )})
    //   console.log(" files.filter((file, i) => id != i ) ",  files.filter((file, i) => id != i ))

  }

  handleShare() {
    const { ccEmail, toEmail, attachedment, fileList } = this.state
    // console.log('fileList', fileList)
    // if (!fileList) {
    //   return;
    // }
    // const fileData = new FormData();
    // fileList.forEach((file, i) => {
    //   fileData.append(`file-${i}`, file, file.name);
    // });
    // console.log('fileData', fileData)

    this.setState({ isLoading: true })
    this.props
      .shareEmail_action(ccEmail, toEmail, fileList)
      .then((data) => {
        if (data.error != 1) {
          setTimeout(() => {
            this.setState({ isLoading: false })
          }, 500);
        }
        else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  toggleModal() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }
  onSearchClick() {
    const { exhibitor, recptDtFrom, recptDtTo, recptNo } = this.state
    if (exhibitor != "" || recptDtFrom != null || recptDtTo != null || recptNo != "") {
      this.searchReceipt()
    } else {
      toastError("Atleast Fill One field to Search")
    }
  }

  // on pdf receipt download
  onReceiptDownload(E, id) {
    this.setState({ isLoading: true })
    this.props.getReceiptPdf_action(id)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ isLoading: false })
          console.log("Proforma Pdf Download", data)
          var url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
          const win = window.location.replace(url, '_blank');
          if (win != null) {
            win.focus();
          }
        } else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((err) => {
        console.log(err)
      })

  }

  getWriteAccess() {
    const { loginAccesslist } = this.state
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
    if (moduleAccess) {
      return moduleAccess.WriteAccess;
    } else {
      return false
    }
    return false;
  }

  getDownloadAccess() {
    const { loginAccesslist } = this.state
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
    if (moduleAccess) {
      return moduleAccess.DownloadAccess;
    } else {
      return false
    }
    return false;
  }

  getSendAccess() {
    const { loginAccesslist } = this.state
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
    if (moduleAccess) {
      return moduleAccess.SendAccess;
    } else {
      return false
    }
    return false;
  }

  getUpdateAccess() {
    const { loginAccesslist } = this.state
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
    if (moduleAccess) {
      return moduleAccess.UpdateAccess;
    } else {
      return false
    }
    return false;
  }

  render() {
    const { exhibitor, recptDtFrom, recptDtTo, recptNo, isLoading, currentPage, recptList, attachedment,
      recptDtFromErr, recptDtToErr, recptNoErr, exhibitorErr, isOpen, ccEmail, toEmail, isAllChecked, fileList,
      taxableAmount, totalReceivedAmt, checkBoxFlag } = this.state
    const recordsPerPage = 10;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
    const currentRecords = recptList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(recptList.length / recordsPerPage)




    // const { taxableAmount, totalCgst, totalIgst, totalSgst, totalReceivedAmt } = this.calculateTotals()
    // const files = fileList ? [...fileList] : [];

    return (
      <>
        <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog" style={{ top: '4em', left: '0px', }}>
            <div className="modal-content" style={{ marginTop: '10px' }}>
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="step-name" style={{ zIndex: '9' }}>
                      <p>Receipt Share</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body p-0" style={{ maxHeight: '350px', overflow: 'auto', }}>
                {/* // share email */}
                <>
                  {/*app-content open*/}
                  <div className="main-content app-content mt-0">

                    <div className="main-container  top-space">
                      <div className="row mt-4 mb-3">
                        <div className="col-md-6">
                          <label>TO</label>
                          <input type="text"
                            className="form-control"
                            value={toEmail}
                            onChange={e => this.setState({ toEmail: e.target.value })} />
                        </div>
                        <div className="col-md-6">
                          <label>CC</label>
                          <input type="text"
                            className="form-control"
                            value={ccEmail}
                            onChange={e => this.setState({ ccEmail: e.target.value })} />
                        </div>
                      </div>
                      <div className="row" style={{ height: '200px', overflow: "auto" }}>
                        <p>
                          <strong>Mr. Microsoft </strong> <br />
                          Please find the Attached Receipt Documents
                        </p>
                      </div>

                    </div>
                  </div>
                </>

              </div>

              <div className="modal-footer p-2">
                <div className="row ">
                  <div className="" role="group" aria-label="Basic example">
                    <button
                      type="button"
                      className="btn ad-fil-btn "
                      style={{ marginRight: '15px', marginLeft: '0px', position: "relative" }}
                    >
                      Attachment 1 <span className="b-can">x</span>
                    </button>
                    <button
                      type="button"
                      className="btn ad-fil-btn "
                      style={{ marginRight: '15px', marginLeft: '0px', position: "relative" }}
                    >
                      Attachment 2 <span className="b-can">x</span>
                    </button>
                    <button
                      type="button"
                      className="btn ad-fil-btn "
                      style={{ marginRight: '15px', marginLeft: '0px', position: "relative" }}
                    >
                      Attachment 3 <span className="b-can">x</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary mx-2"
                      style={{ float: "right", fontSize: '14px', padding: "5px 15px" }}
                      onClick={e => this.setState({ isOpen: !isOpen })}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{ float: "right", fontSize: '14px', padding: "5px 15px" }}
                      onClick={e => this.handleShare()}
                    >
                      Send
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </Modal>

        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <ToastContainer />
        {
          JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
            <ExhibitorHeader /> : <HeaderWithLogo />
        }
        <div
          //  className="with-dr-container com-height"
          className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}
        >
          <div className="main-container">
            {
              JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                <ExhibitorDrawer /> : <AdminDrawer />
            }
            {
              JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                <ExhibitorTopMenu /> : null
            }

            <div className="row">
              <div className="step-name">
                <p>Receipt</p>
              </div>
            </div>
          </div>
          <div className="main-container p-2 mt-1">
            {
              JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
              <div className="row ">
                <div className="col-xl-3 mb-3">
                  <label>Exhibitor</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    required=""
                    value={exhibitor}
                    onKeyDown={e => this.handleKeyDown(e)}
                    onChange={(e) => this.setState({ exhibitor: e.target.value, exhibitorErr: "" })}
                  />
                  {/* {<span className='text-danger text-start text-left d-block mt-1'>{exhibitorErr}</span>} */}
                </div>
                <div className="col-xl-3 mb-3">
                  <label>Receipt Date From</label>
                  <DatePicker className="form-control"
                    dateFormat="dd-MM-yyyy"
                    onKeyDown={e => this.handleKeyDown(e)}
                    onChange={(date) => this.setState({ recptDtFrom: date, recptDtFromErr: "" })}
                    selected={this.state.recptDtFrom}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="From Date"
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                  />
                  {/* {<span className='text-danger text-start text-left d-block mt-1'>{recptDtFromErr}</span>} */}
                </div>
                <div className="col-xl-3 mb-3">
                  <label>Receipt Date To</label>
                  <DatePicker className="form-control"
                    dateFormat="dd-MM-yyyy"
                    minDate={this.state.recptDtFrom}
                    onKeyDown={e => this.handleKeyDown(e)}
                    onChange={(date) => this.setState({ recptDtTo: date, recptDtToErr: "" })}
                    selected={this.state.recptDtTo}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="To Date"
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                  />
                  {/* {<span className='text-danger text-start text-left d-block mt-1'>{recptDtToErr}</span>} */}
                </div>
                <div className="col-xl-3 mb-3">
                  <label>Receipt No</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    value={recptNo}
                    onKeyDown={e => this.handleKeyDown(e)}
                    onChange={(e) => this.setState({ recptNo: e.target.value, recptNoErr: "" })}
                  />
                  {/* {<span className='text-danger text-start text-left d-block mt-1'>{recptNoErr}</span>} */}
                </div>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                {
                  JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                  <div
                    className="btn-group mb-1"
                    role="group"
                    aria-label="Basic example"
                    style={{ float: "right" }}
                  >
                    <button type="button" className="btn ad-fil-btn" onClick={e => this.onSearchClick()}>
                      Search
                    </button>
                    <button type="button" className="btn ad-fil-btn"
                      disabled={!this.getWriteAccess()}
                      onClick={e => this.handleNewReceipt()} >
                      New Receipt
                    </button>
                    {this.onCreateExcel()}
                    {/* <button type="button" className="btn ad-fil-btn" onClick={e => this.toggleModal()} >
                    onClick={e => this.toggleModal()}
                    Share
                  </button> */}
                    <button type="button"
                      disabled={checkBoxFlag || this.getSendAccess()}
                      className="btn ad-fil-btn" onClick={e => this.onShare()}>
                      Share
                    </button>

                    <button type="button" className="btn ad-fil-btn" onClick={e => this.clearValue()}>
                      Clear
                    </button>
                  </div>
                }
                <div className="table-responsive">
                  <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                    <thead>
                      <tr>
                        {
                          JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                          <th className="text-center">
                            <input type="checkbox" value={isAllChecked} checked={isAllChecked} onClick={e => this.handleAllSelected(e)} />
                          </th>
                        }
                        {/* <th>Reference No </th> */}
                        <th>Receipt No</th>
                        {
                          JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                          <th>Company Name</th>
                        }
                        <th>Receipt Date </th>
                        <th>Taxable Amount </th>
                        <th>Security Deposite </th>
                        <th>CGST </th>
                        <th>SGST </th>
                        <th>IGST </th>
                        <th>Receipt Amount</th>
                        <th>Receipt Bank </th>
                        {
                          JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                          <th></th>
                        }
                        {
                          this.getUpdateAccess() &&
                          <th></th>
                        }
                        <th></th>
                        <th></th>
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        currentRecords.length > 0 ?
                          currentRecords.map((data, index) => (
                            <>
                              <tr >
                                {
                                  JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                  <td className="text-center">
                                    <input type="checkbox"
                                      value={data.is_checked}
                                      checked={data.is_checked}
                                      onClick={e => this.handleListClick(e, data.ReceiptID)} />
                                  </td>
                                }
                                {/* <td>{data.ReferenceNo}</td> */}
                                <td>{data.ReceiptNo}</td>
                                {
                                  JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                  <td>{data.ExhibitorName}</td>
                                }
                                <td>{data.ReceiptDate}</td>
                                <td>{data.TaxableAmount}</td>
                                <td>{data.SecurityDeposite}</td>
                                <td>{data.CGST}</td>
                                <td>{data.SGST}</td>
                                <td>{data.IGST}</td>
                                <td>{data.Amount}</td>
                                <td>{data.ReceiptBank}</td>
                                {
                                  JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                  <td style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-eye" aria-hidden="true" onClick={e => this.handleView(data.ReceiptID)}
                                    // disabled={!isRightToView}
                                    />
                                  </td>
                                }
                                {
                                  this.getUpdateAccess() &&
                                  <td style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-pencil" aria-hidden="true" onClick={e => this.handleUpdate(data.ReceiptID)}
                                    // disabled={!isRightToEdit}
                                    />
                                  </td>
                                }
                                <td style={{ cursor: 'pointer' }}>
                                  <i className="fa fa-download" aria-hidden="true" onClick={(e) => this.onReceiptDownload(e, data.ReceiptID)} />
                                </td>
                                <td style={{ cursor: 'pointer' }}>
                                  <i class="fa fa-share" onClick={(e) => this.onShare(data.ReceiptID)}></i>
                                </td>
                              </tr>
                            </>
                          )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                      }
                      {
                        currentRecords.length > 0 &&
                        <tr>
                          {/* <td /> */}
                          {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                            <td />
                          }
                          {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                            <td />
                          }
                          <td />
                          <td />
                          <td>
                            <strong>{taxableAmount}</strong>
                          </td>
                          <td>
                            <strong>
                              {/* {this.state.totalSD} */}
                            </strong>
                          </td>
                          <td>
                            <strong></strong>
                          </td>
                          <td>
                            <strong></strong>
                          </td>
                          <td>
                            <strong></strong>
                          </td>
                          <td>
                            <strong>{totalReceivedAmt}</strong>
                          </td>
                          <td />
                          <td />
                          {/* <td /> */}
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
                <div className="mt-2 float-left">
                  {
                    recptList.length > 0 ?
                      <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={this.updateItem}
                      /> : <></>
                  }
                  <div className="tab-rec " style={{ float: "right" }}>
                    <p>{`${currentPage} of ${nPages} pages`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
          <Footerwithdr />
        }
      </>
    )
  }
}
export default withRouter(connect(null, { receipt_list_search_action, shareEmail_action, getEmailData_action, getReceiptPdf_action, receiptShare_action })(ReceiptList));