import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../common_components/Header";
import Footer from "../../common_components/Footer";
import Drawer from "../../common_components/Drawer";
import {
  registrationOneAdd_action,
  registrationOneUpdate_action,
  getregistrationDetails_action,
  getAgentDetails_action,
  getPendingRegDetails_action,
} from "../../actions/registration_action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "../../utils/withRouter";
import moment from "moment";
import Modal from "react-modal";
import Loader from "../../utils/Loader";
import {
  clearSessionStorage,
  countryActionId,
  stateActionId,
  cityActionId,
  pincodeActionId,
  verifyPrimaryEmailIDActionId,
  toastError, GSTvalidationActionId,
  toastSuccess,
} from "../../utils/constants";
import Pagination from "../../utils/pagination";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from "../../actions/fillCombo_action";
import StepHeader from "./stepHeader";
import PrimaryChapter from "./primary_chapter";
import Description from "./description";
import Resizer from "react-image-file-resizer";
import {
  contactNoValidator,
  panNoValidator,
  tanNoValidator,
  gstNoValidator,
  emailValidator,
} from "../../utils/Validator";
import $ from "jquery";

/*
 * mapDispatchToProps
 */
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state,
});

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCorrespondenceDetails: false,
      isFirstStepCompleted: false,
      exhibitionID: "",
      is_update: false,
      isCountryIndia: false,
      countryNamePre: "",
      countryNamePreRegisterMobileNo: "",
      countryNamePreRegisterAlternateMobileNo: "",
      countryNamePreOwnerMobileNo: "",
      countryNamePreInvoiceregisterMobileNo: "",
      countryNamePreInvoiceregisterAlternateMobileNo: "",

      isPrimaryEmailId: false,
      isSecondaryEmailId: false,
      isOwenerEmailId: false,

      isInvoiceprimaryEmailId: false,
      isInvoicesecondaryEmailId: false,

      registerTelephoneNoPre: "",
      registerTelephoneNo1Pre: "",

      invLastNamePersonIncharge: "",
      lastNamePersonIncharge: "",
      invLastNamePersonInchargeErr: "",
      lastNamePersonInchargeErr: "",

      invoiceregisterTelephoneNoPre: "",
      invoiceregisterTelephoneNo1Pre: "",

      formFileType: "",
      declarationFileType: "",
      trcFileType: "",
      isSelfPaid: false,

      countryList: [],
      stateList: [],
      cityList: [],
      pincodeList: [],
      tempStateList: [],
      tempCityList: [],
      tempPincodeList: [],
      _pincodeList: [],
      isEmailExist: false,

      InvtempStateList: [],
      InvtempCityList: [],
      InvtempPincodeList: [],

      formFile: "",
      declarationFile: "",
      trcFile: "",

      countryId: "",
      countryName: "",
      registerMobileNo: "",
      registerMobileNoErr: "",

      companyName: "",
      companyId: "",
      fasciaName: "",
      address1: "",
      address2: "",
      cityId: "",
      cityName: "",
      stateId: "",
      stateName: "",
      pinCode: "",
      inchargeFirstName: "",
      designation: "",
      registerAlternateMobileNo: "",
      registerTelephoneNo: "",
      registerTelephoneNo1: "",
      registerTelefaxNo: "",
      primaryEmailId: "",
      secondaryEmailId: "",
      website: "",
      nameOfOwner: "",
      lastName: "",
      ownerMobileNo: "",
      ownerDesignation: "",
      ownerEmailId: "",
      countryId: "",

      title_inchargeFirstName: "Mr",
      title_name_Of_CEO_MD_President_Owner: "Mr",
      title_InvPersonIncharge: "Mr",

      isOther_title_inchargeFirstName: false,
      isOther_title_name_Of_CEO_MD_President_Owner: false,
      isOther_title_InvPersonIncharge: false,

      other_title_inchargeFirstName: "",
      other_title_name_Of_CEO_MD_President_Owner: "",
      other_title_InvPersonIncharge: "",

      invoicecompanyName: "",
      invoicecompanyId: "",
      invoicefasciaName: "",
      invoiceaddress1: "",
      invoiceaddress2: "",
      invoicecityId: "",
      invoicecityName: "",
      invoicestateId: "",
      invoicestateName: "",
      invoicepinCode: "",
      invoiceinchargeFirstName: "",
      invoicedesignation: "",
      invoiceregisterMobileNo: "",
      invoiceregisterAlternateMobileNo: "",
      invoiceregisterTelephoneNo: "",
      invoiceregisterTelephoneNo1: "",
      invoiceregisterTelefaxNo: "",
      invoiceprimaryEmailId: "",
      invoicesecondaryEmailId: "",
      invoicewebsite: "",
      invoicenameOfOwner: "",
      invoicelastName: "",
      invoiceownerMobileNo: "",
      invoiceownerEmailId: "",
      countryName: "",
      invoicecountryName: "",
      invoicecountryid: "",
      compInfoNameOnPanCard: "",
      compInfoPanNo: "",
      compInfoTAN: "",
      compInfoGstNo: "",
      compInfoRegistrationNo: "",
      compInfoIecCode: "",

      bankDtlAccountName: "",
      bankDtlAccountNo: "",
      bankDtlAccountType: "",
      bankDtlAccountTypeOthers: "",
      bankDtlBankName: "",
      bankDtlBranchName: "",
      bankDtlIfscCode: "",

      // error state
      companyNameErr: "",
      fasciaNameErr: "",
      address1Err: "",
      address2Err: "",
      registerMobileNoErr: "",
      cityNameErr: "",
      stateNameErr: "",
      pinCodeErr: "",
      countryNameErr: "",

      inchargeFirstNameErr: "",
      designationErr: "",
      registerAlternateMobileNoErr: "",
      registerTelephoneNoErr: "",
      registerTelefaxNoErr: "",
      primaryEmailIdErr: "",
      secondaryEmailIdErr: "",
      websiteErr: "",
      nameOfOwnerErr: "",

      lastNameErr: "",
      ownerMobileNoErr: "",
      ownerDesignationErr: "",
      ownerEmailIdErr: "",

      invoicecompanyNameErr: "",
      invoicecompanyIdErr: 0,
      invoicefasciaNameErr: "",
      invoiceaddress1Err: "",
      invoiceaddress2Err: "",
      invoicecityIdErr: 0,
      invoicecityNameErr: "",
      invoicestateIdErr: 0,
      invoicestateNameErr: "",
      invoicepinCodeErr: "",
      invoicecountryNameErr: "",
      invoiceinchargeFirstNameErr: "",
      invoicedesignationErr: "",
      invoiceregisterMobileNoErr: "",
      invoiceregisterAlternateMobileNoErr: "",
      invoiceregisterTelephoneNoErr: "",
      invoiceregisterTelefaxNoErr: "",
      invoiceprimaryEmailIdErr: "",
      invoicesecondaryEmailIdErr: "",
      invoicewebsiteErr: "",
      invoicenameOfOwnerErr: "",
      invoicelastNameErr: "",
      invoiceownerMobileNoErr: "",
      invoiceownerEmailIdErr: "",
      countryNameErr: "",

      compInfoNameOnPanCardErr: "",
      compInfoPanNoErr: "",
      compInfoTANErr: "",
      compInfoGstNoErr: "",
      compInfoRegistrationNoErr: "",
      compInfoIecCodeErr: "",

      bankDtlAccountNameErr: "",
      bankDtlAccountNoErr: "",
      bankDtlAccountTypeErr: "",
      bankDtlBankNameErr: "",
      bankDtlBranchNameErr: "",
      bankDtlIfscCodeErr: "",

      exhRegistrationID: 0,
      exhibitionID: 0,
      exhRegistrationNo: "",
      exhRegistrationDate: "",

      isDisplayCityList: false,
      isDisplayStateList: false,
      isDisplayPincodeList: false,

      isDisplayInvCityList: false,
      isDisplayInvStateList: false,
      isDisplayInvPincodeList: false,
      isDataLoad: false,

      namePrefix: ["Mr", "Ms", "DR", "Prof"],
      gstValidtionList: [],
    };
    this.getFillList = this.getFillList.bind(this);
    this.inputValidate = this.inputValidate.bind(this);
    this.preventMinus = this.preventMinus.bind(this);
    this.onlyNumbers = this.onlyNumbers.bind(this);
    this.maxLengthCheck = this.maxLengthCheck.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onSendRegData = this.onSendRegData.bind(this);
    this.onCorrespondenceDetailsClick =
      this.onCorrespondenceDetailsClick.bind(this);
    this.emailFocusOut = this.emailFocusOut.bind(this);
    this.onFilePick = this.onFilePick.bind(this);
    this.resizeFile = this.resizeFile.bind(this);
    this.onPdfSelect = this.onPdfSelect.bind(this);

    this.onCitySearch = this.onCitySearch.bind(this);
    this.renderCityList = this.renderCityList.bind(this);
    this.callStateList = this.callStateList.bind(this);

    this.onStateSearch = this.onStateSearch.bind(this);
    this.renderStateList = this.renderStateList.bind(this);

    this.onPincodeSearch = this.onPincodeSearch.bind(this);
    this.renderPincodeList = this.renderPincodeList.bind(this);

    this.getRegistrationDetails = this.getRegistrationDetails.bind(this);
    this.getAgentDetails = this.getAgentDetails.bind(this);
    this.onSelectCityId = this.onSelectCityId.bind(this);
    this.onSelectStateId = this.onSelectStateId.bind(this);
    this.onSelectPincodeId = this.onSelectPincodeId.bind(this);

    this.onInvCitySearch = this.onInvCitySearch.bind(this);
    this.renderInvCityList = this.renderInvCityList.bind(this);
    this.callInvStateList = this.callInvStateList.bind(this);

    this.onInvStateSearch = this.onInvStateSearch.bind(this);
    this.renderInvStateList = this.renderInvStateList.bind(this);

    this.onInvPincodeSearch = this.onInvPincodeSearch.bind(this);
    this.renderInvPincodeList = this.renderInvPincodeList.bind(this);
    this.checkCountryId = this.checkCountryId.bind(this);
    this.invemailFocusOut = this.invemailFocusOut.bind(this);
    this.getPendingRegDetailsByCode =
      this.getPendingRegDetailsByCode.bind(this);
    this.loadscript = this.loadscript.bind(this);
    this.onGSTchange = this.onGSTchange.bind(this);
    this.onChangePrimaryEmail = this.onChangePrimaryEmail.bind(this);
  }

  onSelectCityId(e) {
    e.preventDefault();
    const { cityId, cityList, cityName } = this.state;

    let cityData = cityList.filter((x) => x.Code == e.target.value)[0];
    this.setState({ cityId: cityData.Code, cityName: cityData.Description });

    this.getFillList(pincodeActionId, "", "", cityData.Code, "", "");

    // cityList.map(data => {
    //     data.Code == cityName && this.setState({ cityId: data.Code, cityName: data.Description , cityNameErr:''})
    // });
  }

  onSelectStateId(e) {
    e.preventDefault();
    const {
      stateId,
      stateList,
      stateName,
      _pincodeList,
      pincodeList,
      cityName,
    } = this.state;
    console.log(stateName)
    stateList.map((data) => {
      data.Code == stateName &&
        this.setState({
          stateId: data.Code,
          stateName: data.Description,
          stateNameErr: "",
          _pincodeList: pincodeList.filter(
            (x) => x.StateName == stateName && x.CityName == cityName
          ),
        });
    });
  }

  onSelectPincodeId(e) {
    e.preventDefault();
    const { pincodeId, pincodeList, pinCode } = this.state;
    pincodeList.map((data) => {
      data.Code == pinCode &&
        this.setState({
          pincodeId: data.Code,
          pinCode: data.Description,
          pinCodeErr: "",
        });
    });
  }

  componentDidMount() {
    console.log("prop is :", this.props);
    if (this.props.location.pathname.includes("/registration/")) {
      var code = this.props.location.pathname.replace("/registration/", "");
      sessionStorage.setItem("renderPage", "registerForm");
      // this.setState({
      //     countryId: '',
      //     countryName: '',
      //     exhibitionID: '',
      //     countryNamePre: '',
      //     // exhibitionID: sessionStorage.getItem("sessionExhibitionID"),
      //     isCountryIndia: false,
      //     is_update: false,
      //     countryNamePreRegisterMobileNo: sessionStorage.getItem("sessionContryNamePre"),
      //     countryNamePreRegisterAlternateMobileNo: sessionStorage.getItem("sessionContryNamePre"),
      //     countryNamePreOwnerMobileNo: sessionStorage.getItem("sessionContryNamePre"),
      //     countryNamePreInvoiceregisterMobileNo: sessionStorage.getItem("sessionContryNamePre"),
      //     countryNamePreInvoiceregisterAlternateMobileNo: sessionStorage.getItem("sessionContryNamePre"),
      // });
      console.log('this.props.location.state', this.props.location.state)
      this.getPendingRegDetailsByCode(code).then(agentCode => {
        agentCode != null && sessionStorage.setItem("agentCode", agentCode)
      })
      this.loadscript();
    } else {
      sessionStorage.setItem("renderPage", "registerForm");
      this.setState({
        isDataLoad: true,
        countryId:
          this.props.location.state != "" &&
            this.props.location.state.countryId != " "
            ? this.props.location.state.countryId
            : "",
        countryName:
          this.props.location.state != "" &&
            this.props.location.state.countryName != " "
            ? this.props.location.state.countryName
            : "",
        exhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        countryNamePre: sessionStorage.getItem("sessionContryNamePre"),
        // exhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        isCountryIndia:
          this.props.location.state != "" &&
            this.props.location.state.countryName == "India "
            ? true
            : false,
        is_update:
          this.props.location.state != "" &&
          this.props.location.state.is_update != " " &&
          this.props.location.state.is_update,
        countryNamePreRegisterMobileNo: sessionStorage.getItem(
          "sessionContryNamePre"
        ),
        countryNamePreRegisterAlternateMobileNo: sessionStorage.getItem(
          "sessionContryNamePre"
        ),
        countryNamePreOwnerMobileNo: sessionStorage.getItem(
          "sessionContryNamePre"
        ),
        countryNamePreInvoiceregisterMobileNo: sessionStorage.getItem(
          "sessionContryNamePre"
        ),
        countryNamePreInvoiceregisterAlternateMobileNo: sessionStorage.getItem(
          "sessionContryNamePre"
        ),
      });
      this.getFillList(
        countryActionId,
        sessionStorage.getItem("sessionExhibitionID"),
        "",
        "",
        "",
        ""
      );
      this.getFillList(
        cityActionId,
        sessionStorage.getItem("sessionExhibitionID"),
        "",
        this.props.location.state.countryId,
        "",
        ""
      );
      this.getFillList(
        stateActionId,
        sessionStorage.getItem("sessionExhibitionID"),
        "",
        this.props.location.state.countryId,
        "",
        ""
      );
      this.getFillList(
        pincodeActionId,
        sessionStorage.getItem("sessionExhibitionID"),
        "",
        this.props.location.state.countryId,
        "",
        ""
      );
      this.getFillList(
        GSTvalidationActionId,
        sessionStorage.getItem("sessionExhibitionID"),
        "",
        this.props.location.state.countryId,
        "",
        ""
      );
      // console.log("7879", this.props.location.state.countryId)

      setTimeout(() => {
        this.props.location.state.is_update && this.getRegistrationDetails();
      }, 200);
      this.checkCountryId(
        this.props.location.state != "" &&
          this.props.location.state.countryName != " "
          ? this.props.location.state.countryName
          : ""
      );
      this.loadscript();
      if (
        this.props.location != null &&
        this.props.location.state != null &&
        !this.props.location.state.is_update &&
        sessionStorage.getItem("agentCode") != null &&
        sessionStorage.getItem("agentCode") != ""
      ) {
        this.getAgentDetails();
      } else {
        const { countryName, countryId } = this.state;
        this.setState({
          invoicecountryid:
            this.props.location.state != "" &&
              this.props.location.state.countryId != " "
              ? this.props.location.state.countryId
              : "",
          invoicecountryName:
            this.props.location.state != "" &&
              this.props.location.state.countryName != " "
              ? this.props.location.state.countryName
              : "",
        });
      }
    }
  }

  loadscript() {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }

  checkCountryId(countryName) {
    // var countryName = this.props.location.state != "" && this.props.location.state.countryName != " " ? this.props.location.state.countryName : ''
    if (countryName == "") {
      this.setState({
        countryNameErr: "Please select country",
      });
    }
  }
  getPendingRegDetailsByCode(code) {
    console.log("code is : ", code);
    const { } = this.state;

    this.setState({ isLoading: true });
    return new Promise((resolve, reject) => {
      this.props
        .getPendingRegDetails_action(code)
        .then((data) => {
          if (data.error != 1) {
            console.log("data is : ", data.data[0]);
            console.log(
              "ExhRegistrationID is : ",
              data.data[0].ExhRegistrationID
            );

            sessionStorage.setItem(
              "sessionExhibitionID",
              data.data.length > 0 ? data.data[0].ExhibitionID : ""
            );
            sessionStorage.setItem(
              "sessionExhRegistrationID",
              data.data.length > 0 ? data.data[0].ExhRegistrationID : ""
            );
            sessionStorage.setItem(
              "sessionExhRegistrationNo",
              data.data.length > 0 ? data.data[0].ExhRegistrationNo : ""
            );
            sessionStorage.setItem(
              "sessionContryNamePre",
              data.data.length > 0 ? data.data[0].CountryCode : ""
            );
            sessionStorage.setItem("isCountrySelected", true);
            sessionStorage.setItem("isFirstStepCompleted", true);
            sessionStorage.setItem(
              "sessionContryName",
              data.data.length > 0 ? data.data[0].CountryName : ""
            );
            sessionStorage.setItem(
              "countryId",
              data.data.length > 0 ? data.data[0].CountryID : ""
            );

            var emailArr =
              data.data.length > 0 &&
                data.data[0].ExhMailEmail != "" &&
                data.data[0].ExhMailEmail != null
                ? data.data[0].ExhMailEmail.split(",")
                : [];
            var mobileArr =
              data.data.length > 0 &&
                data.data[0].ExhMailMobile != "" &&
                data.data[0].ExhMailMobile != null
                ? data.data[0].ExhMailMobile.split(",")
                : [];
            sessionStorage.setItem(
              "exhibitionAddress",
              data.data.length > 0 ? data.data[0]["Address"] : ""
            );
            sessionStorage.setItem(
              "sessionBannerPath",
              data.data.length > 0 ? data.data[0]["Banner"] : ""
            );
            sessionStorage.setItem(
              "exhibitionDateFrom",
              data.data.length > 0 ? data.data[0]["ExhibitionDateFrom"] : ""
            );
            sessionStorage.setItem(
              "exhibitionDateTo",
              data.data.length > 0 ? data.data[0]["ExhibitionDateTo"] : ""
            );
            sessionStorage.setItem(
              "sessionLogoPath",
              data.data.length > 0 ? data.data[0]["Logo"] : ""
            );
            sessionStorage.setItem(
              "sessionExhibitionName",
              data.data.length > 0 ? data.data[0]["ExhibitionName"] : ""
            );
            sessionStorage.setItem(
              "sessionExhibitionEmail",
              JSON.stringify(emailArr)
            );
            sessionStorage.setItem(
              "sessionExhibitionMobileNo",
              JSON.stringify(mobileArr)
            );

            // if (this.props.location.state.countryName != "India ") {
            //   this.setState({
            //     isCountryIndia: data.data.length > 0 && data.data[0].CountryID == 91
            //       ? true
            //       : false,
            //   })
            // }
            setTimeout(() => {
              var agentCode = data.data.length > 0 ? data.data[0].agentcode != '' && data.data[0].agentcode != null ? data.data[0].agentcode : null : null
              // this.setState({ isLoading: false });
              this.setState({
                isDataLoad: true,
                countryId: data.data.length > 0 ? data.data[0].CountryID : "",
                countryName: data.data.length > 0 ? data.data[0].CountryName : "",
                exhibitionID:
                  data.data.length > 0 ? data.data[0].ExhibitionID : "",
                countryNamePre:
                  data.data.length > 0 ? data.data[0].CountryCode : "",
                // exhibitionID: sessionStorage.getItem("sessionExhibitionID"),

                is_update: true,
                countryNamePreRegisterMobileNo:
                  data.data.length > 0 ? data.data[0].CountryCode : "",
                countryNamePreRegisterAlternateMobileNo:
                  data.data.length > 0 ? data.data[0].CountryCode : "",
                countryNamePreOwnerMobileNo:
                  data.data.length > 0 ? data.data[0].CountryCode : "",
                countryNamePreInvoiceregisterMobileNo:
                  data.data.length > 0 ? data.data[0].CountryCode : "",
                countryNamePreInvoiceregisterAlternateMobileNo:
                  data.data.length > 0 ? data.data[0].CountryCode : "",
              });
              this.getFillList(
                countryActionId,
                data.data.length > 0 ? data.data[0].ExhibitionID : "",
                "",
                "",
                "",
                ""
              );
              this.getFillList(
                cityActionId,
                data.data.length > 0 ? data.data[0].ExhibitionID : "",
                "",
                data.data.length > 0 ? data.data[0].CountryID : "",
                "",
                ""
              );
              this.getFillList(
                stateActionId,
                data.data.length > 0 ? data.data[0].ExhibitionID : "",
                "",
                data.data.length > 0 ? data.data[0].CountryID : "",
                "",
                ""
              );
              this.getFillList(
                pincodeActionId,
                data.data.length > 0 ? data.data[0].ExhibitionID : "",
                "",
                data.data.length > 0 ? data.data[0].CountryID : "",
                "",
                ""
              );
              this.getFillList(
                GSTvalidationActionId,
                data.data.length > 0 ? data.data[0].ExhibitionID : "",
                "",
                data.data.length > 0 ? data.data[0].CountryID : "",
                "",
                ""
              );
              this.getRegistrationDetails();
              this.checkCountryId(
                data.data.length > 0 ? data.data[0].CountryName : ""
              );
              resolve(agentCode)
            }, 500);
          } else {
            this.setState({ isLoading: false });
            if (data.msg.includes("Registration completed with ")) {
              sessionStorage.setItem(
                "sessionExhRegistrationNo",
                data.msg.replace("Registration completed with ", "")
              );
              this.props.navigate("/thankYou");
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    })
  }
  // render city
  renderCityList() {
    const { cityList, cityName, tempCityList } = this.state;
    return (
      <ul
        class="list-group pincode-drop"
        style={{ position: "absolute", zIndex: 1 }}
      >
        {tempCityList.length > 0 &&
          tempCityList.map((data) => (
            <li
              class="list-group-item"
              key={Math.random()}
              onClick={(e) =>
                this.setState({
                  cityName: data.Description,
                  isDisplayCityList: false,
                })
              }
            >
              {data.Description}
            </li>
          ))}
      </ul>
    );
  }
  // searcg city
  onCitySearch(e) {
    const { cityList, cityName, tempCityList } = this.state;
    if (e.target.value != "") {
      var searchCityList = tempCityList.filter((data) => {
        return data.Description.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      this.setState({
        cityName: e.target.value,
        cityNameErr: "",
        tempCityList: searchCityList,
        isDisplayCityList: true,
      });
      // call state fill list pass city id
      //this.getFillList(stateActionId,"","",)
    } else {
      this.setState({
        cityName: e.target.value,
        tempCityList: cityList,
        isDisplayCityList: false,
      });
    }
  }
  // state list
  callStateList(value, id) {
    const { stateList, stateName, tempStateList } = this.state;
    this.setState({ stateName: value, stateId: id, isDisplayStateList: false });
    this.getFillList(stateActionId, "", "", "", stateName, "", "");
  }

  // render state
  renderStateList() {
    const { stateList, stateName, tempStateList } = this.state;
    return (
      <ul
        class="list-group pincode-drop"
        style={{ position: "absolute", zIndex: 1 }}
      >
        {tempStateList.length > 0 &&
          tempStateList.map((data) => (
            <li
              class="list-group-item"
              key={Math.random()}
              onClick={(e) => this.callStateList(data.Description, data.Code)}
            >
              {data.Description}
            </li>
          ))}
      </ul>
    );
  }
  // search state
  onStateSearch(e) {
    const { stateList, stateName, tempStateList } = this.state;
    console.log("onStateSearch");
    if (e.target.value != "") {
      var searchStateList = tempStateList.filter((data) => {
        return data.Description.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      this.setState({
        stateName: e.target.value,
        stateNameErr: "",
        tempStateList: searchStateList,
        isDisplayStateList: true,
      });
      // call pincode fill list pass city id and state id
      // this.getFillList(pincodeActionId, '', '', '', stateName, '')
    } else {
      this.setState({
        stateName: e.target.value,
        tempStateList: stateList,
        isDisplayStateList: false,
      });
    }
  }

  // render pincode
  renderPincodeList() {
    const { pincodeList, pinCode, tempPincodeList } = this.state;
    return (
      <ul
        class="list-group pincode-drop"
        style={{ position: "absolute", zIndex: 1 }}
      >
        {tempPincodeList.length > 0 &&
          tempPincodeList.map((data) => (
            <li
              class="list-group-item"
              key={Math.random()}
              onClick={(e) =>
                this.setState({
                  pinCode: data.Pincode,
                  isDisplayPincodeList: false,
                })
              }
            >
              {data.Pincode}
            </li>
          ))}
      </ul>
    );
  }
  // search pincode
  onPincodeSearch(e) {
    const { pincodeList, pinCode, tempPincodeList } = this.state;
    console.log("onPincodeSearch 123", tempPincodeList);
    if (e.target.value != "") {
      var searchPincodeList = tempPincodeList.filter((data) => {
        return data.Pincode.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      console.log("pincodeCount", searchPincodeList.length);
      this.setState({
        pinCode: e.target.value,
        pinCodeErr: "",
        tempPincodeList: searchPincodeList,
        isDisplayPincodeList: true,
      });
    } else {
      this.setState({
        pinCode: e.target.value,
        tempPincodeList: pincodeList,
        isDisplayPincodeList: false,
      });
    }
  }

  // FOR INVOICE DETAILS

  // render city
  renderInvCityList() {
    const { cityList, invoicecityName, InvtempCityList } = this.state;
    return (
      <ul
        className="list-group pincode-drop"
        style={{ position: "absolute", zIndex: 1 }}
      >
        {InvtempCityList.length > 0 &&
          InvtempCityList.map((data) => (
            <li
              className="list-group-item"
              key={Math.random()}
              onClick={(e) =>
                this.setState({
                  invoicecityName: data.Description,
                  isDisplayInvCityList: false,
                })
              }
            >
              {data.Description}
            </li>
          ))}
      </ul>
    );
  }
  // search city
  onInvCitySearch(e) {
    const { cityList, cityName, InvtempCityList } = this.state;
    if (e.target.value != "") {
      var searchInvCityList = InvtempCityList.filter((data) => {
        return data.Description.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      this.setState({
        invoicecityName: e.target.value,
        invoicecityNameErr: "",
        InvtempCityList: searchInvCityList,
        isDisplayInvCityList: true,
      });
      // call state fill list pass city id
      //this.getFillList(stateActionId,"","",)
    } else {
      this.setState({
        invoicecityName: e.target.value,
        InvtempCityList: cityList,
        isDisplayInvCityList: false,
      });
    }
  }

  // state list
  callInvStateList(value) {
    const { stateList, invoicestateName, InvtempStateList } = this.state;
    this.setState({ invoicestateName: value, isDisplayInvStateList: false });
    this.getFillList(stateActionId, "", "", "", invoicestateName, "", "");
  }

  // render state
  renderInvStateList() {
    const { stateList, invoicestateName, InvtempStateList } = this.state;
    return (
      <ul
        class="list-group pincode-drop"
        style={{ position: "absolute", zIndex: 1 }}
      >
        {InvtempStateList.length > 0 &&
          InvtempStateList.map((data) => (
            <li
              class="list-group-item"
              key={Math.random()}
              onClick={(e) => this.callInvStateList(data.Description)}
            >
              {data.Description}
            </li>
          ))}
      </ul>
    );
  }

  // search state
  onInvStateSearch(e) {
    const { stateList, invoicestateName, InvtempStateList } = this.state;
    console.log("onStateSearch");
    if (e.target.value != "") {
      var searchInvStateList = InvtempStateList.filter((data) => {
        return data.Description.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      this.setState({
        invoicestateName: e.target.value,
        invoicestateNameErr: "",
        InvtempStateList: searchInvStateList,
        isDisplayInvStateList: true,
      });
      // call pincode fill list pass city id and state id
      // this.getFillList(pincodeActionId, '', '', '', stateName, '')
    } else {
      this.setState({
        invoicestateName: e.target.value,
        InvtempStateList: stateList,
        isDisplayInvStateList: false,
      });
    }
  }
  // render pincode
  renderInvPincodeList() {
    const { pincodeList, invoicepinCode, InvtempPincodeList } = this.state;
    return (
      <ul
        class="list-group pincode-drop"
        style={{ position: "absolute", zIndex: 1 }}
      >
        {InvtempPincodeList.length > 0 &&
          InvtempPincodeList.map((data) => (
            <li
              class="list-group-item"
              key={Math.random()}
              onClick={(e) =>
                this.setState({
                  invoicepinCode: data.Pincode,
                  isDisplayInvPincodeList: false,
                })
              }
            >
              {data.Pincode}
            </li>
          ))}
      </ul>
    );
  }
  // search pincode
  onInvPincodeSearch(e) {
    const { pincodeList, invoicepinCode, InvtempPincodeList, invoicecityName } =
      this.state;
    console.log("onPincodeSearch 123", InvtempPincodeList);
    if (e.target.value != "" || invoicecityName != "") {
      var searchInvPincodeList = InvtempPincodeList.filter(
        (x) => x.CityName == invoicecityName
      );
      console.log("invpincodeCount", searchInvPincodeList.length);
      this.setState({
        invoicepinCode: e.target.value,
        invoicepinCodeErr: "",
        InvtempPincodeList: searchInvPincodeList,
        isDisplayInvPincodeList: true,
      });
    } else {
      this.setState({
        invoicepinCode: e.target.value,
        InvtempPincodeList: pincodeList,
        isDisplayInvPincodeList: false,
      });
    }
  }

  getRegistrationDetails() {
    const {
      isCorrespondenceDetails,
      namePrefix,
      countryId,
      countryName,
      registerMobileNo,
      registerMobileNoErr,
      companyName,
      companyId,
      fasciaName,
      address1,
      address2,
      cityId,
      cityName,
      stateId,
      stateName,
      pinCode,
      inchargeFirstName,
      designation,
      registerAlternateMobileNo,
      registerTelephoneNo,
      registerTelephoneNo1,
      registerTelefaxNo,
      primaryEmailId,
      secondaryEmailId,
      website,
      nameOfOwner,
      lastName,
      ownerMobileNo,
      ownerEmailId,

      isOther_title_InvPersonIncharge,
      isOther_title_inchargeFirstName,
      isOther_title_name_Of_CEO_MD_President_Owner,

      other_title_InvPersonIncharge,
      other_title_inchargeFirstName,
      other_title_name_Of_CEO_MD_President_Owner,

      companyNameErr,
      fasciaNameErr,
      address1Err,
      cityNameErr,
      stateNameErr,
      pinCodeErr,
      countryNameErr,
      inchargeFirstNameErr,
      designationErr,
      registerAlternateMobileNoErr,
      registerTelephoneNoErr,
      registerTelefaxNoErr,
      primaryEmailIdErr,
      secondaryEmailIdErr,
      websiteErr,
      nameOfOwnerErr,

      lastNameErr,
      ownerMobileNoErr,
      ownerEmailIdErr,

      invoicecompanyName,
      invoicecompanyId,
      invoicecountryName,
      invoicefasciaName,
      invoiceaddress1,
      invoiceaddress2,
      invoicecityId,
      invoicecityName,
      invoicestateId,
      invoicestateName,
      invoicepinCode,
      invoiceinchargeFirstName,
      invoicedesignation,
      invoiceregisterMobileNo,
      invoiceregisterAlternateMobileNo,
      invoiceregisterTelephoneNo,
      invoiceregisterTelephoneNo1,
      invoiceregisterTelefaxNo,
      invoiceprimaryEmailId,
      invoicesecondaryEmailId,
      invoicewebsite,
      invoicenameOfOwner,
      invoicelastName,
      invoiceownerMobileNo,
      invoiceownerEmailId,

      invoicecompanyNameErr,
      invoicecompanyIdErr,
      invoicefasciaNameErr,
      invoiceaddress1Err,
      invoiceaddress2Err,
      invoicecityIdErr,
      invoicecityNameErr,
      invoicestateIdErr,
      invoicestateNameErr,
      invoicepinCodeErr,
      invoiceinchargeFirstNameErr,
      invoicedesignationErr,
      invoiceregisterMobileNoErr,
      invoiceregisterAlternateMobileNoErr,
      invoiceregisterTelephoneNoErr,
      invoiceregisterTelefaxNoErr,
      invoiceprimaryEmailIdErr,
      invoicesecondaryEmailIdErr,
      invoicewebsiteErr,
      invoicenameOfOwnerErr,
      invoicelastNameErr,
      invoiceownerMobileNoErr,
      invoiceownerEmailIdErr,
      invoicecountryNameErr,

      compInfoNameOnPanCard,
      compInfoPanNo,
      compInfoTAN,
      compInfoGstNo,
      compInfoRegistrationNo,
      compInfoIecCode,
      bankDtlAccountName,
      bankDtlAccountNo,
      bankDtlAccountType,
      bankDtlBankName,
      bankDtlBranchName,
      bankDtlIfscCode,

      compInfoNameOnPanCardErr,
      compInfoPanNoErr,
      compInfoTANErr,
      compInfoGstNoErr,
      compInfoRegistrationNoErr,
      compInfoIecCodeErr,
      bankDtlAccountNameErr,
      bankDtlAccountNoErr,
      bankDtlAccountTypeErr,
      bankDtlBankNameErr,
      bankDtlBranchNameErr,
      bankDtlIfscCodeErr,
      formFile,
      trcFile,
      declarationFile,
      exhRegistrationID,
      exhibitionID,
      exhRegistrationNo,
      exhRegistrationDate,
    } = this.state;

    this.setState({ isLoading: true });

    this.props
      .getregistrationDetails_action(
        sessionStorage.getItem("sessionExhRegistrationID"),
        sessionStorage.getItem("sessionExhibitionID")
      )
      .then((data) => {
        if (data.error != 1) {
          console.log("getregistrationDetails_action", data.data)
          // console.log("getregistrationDetails_action", data.data[0]['CountryName'])

          var registerMobileNoList =
            data.data[0]["MobileNo"] != null && data.data[0]["MobileNo"] != ""
              ? data.data[0]["MobileNo"].split("-")
              : ["", ""];
          var registerAlternateMobileNoList =
            data.data[0]["AlternateMobileNo"] != null &&
              data.data[0]["AlternateMobileNo"] != ""
              ? data.data[0]["AlternateMobileNo"].split("-")
              : ["", ""];
          var registerTelephoneNoList =
            data.data[0]["Telephone"] != null && data.data[0]["Telephone"] != ""
              ? data.data[0]["Telephone"].split("-")
              : ["", ""];
          var registerTelephoneNo1List =
            data.data[0]["Telephone1"] != null &&
              data.data[0]["Telephone1"] != ""
              ? data.data[0]["Telephone1"].split("-")
              : ["", ""];
          // var registerTelefaxNoList = data.data[0]['TeleFax'] != null && data.data[0]['TeleFax'] != '' ? data.data[0]['TeleFax'].split('-') : ['',''];
          // var registerTelephoneNo1 = data.data[0]['Telephone1'] != null && data.data[0]['Telephone1'] != '' ? data.data[0]['Telephone1'].split('-') : ['',''];
          var ownerMobileNoList =
            data.data[0]["Mobile"] != null && data.data[0]["Mobile"] != ""
              ? data.data[0]["Mobile"].split("-")
              : ["", ""];
          var invoiceregisterMobileNoList =
            data.data[0]["InvMobileNo"] != null &&
              data.data[0]["InvMobileNo"] != ""
              ? data.data[0]["InvMobileNo"].split("-")
              : ["", ""];
          var invoiceregisterAlternateMobileNoList =
            data.data[0]["InvAlternateMobileNo"] != null &&
              data.data[0]["InvAlternateMobileNo"] != ""
              ? data.data[0]["InvAlternateMobileNo"].split("-")
              : ["", ""];
          var invoiceregisterTelephoneNoList =
            data.data[0]["InvTelephone"] != null &&
              data.data[0]["InvTelephone"] != ""
              ? data.data[0]["InvTelephone"].split("-")
              : ["", ""];
          var invoiceregisterTelephoneNo1List =
            data.data[0]["InvTelephone1"] != null &&
              data.data[0]["InvTelephone1"] != ""
              ? data.data[0]["InvTelephone1"].split("-")
              : ["", ""];
          // var invoiceregisterTelefaxNoList = data.data[0]['InvTeleFax'] != null && data.data[0]['InvTeleFax'] != '' ? data.data[0]['InvTeleFax'].split('-') : ['',''];

          console.log("stateList data------->1", data.data);
          this.state.gstValidtionList.map(item => {
            if (item.StateName == data.data[0]["State"]) {
              this.setState({ stateId: item.StateID })
            }
          })
          // console.log("data.data[0]",item.StateName)
          this.setState({
            // formFile: data.data[1] != null ? data.data[1][0]['Filepath'] : '',
            // declarationFile: data.data[1] != null ? data.data[1][1]['declarationFile'] : '',
            // trcFile: data.data[1] != null ? data.data[1][2]['trcFile'] : '',

            countryId: data.data[0]["CountryID"],
            countryName: sessionStorage.getItem("sessionContryName"),
            registerMobileNo: registerMobileNoList[1],
            companyName: data.data[0]["CompanyName"],
            companyId: data.data[0]["CompanyID"],
            fasciaName: data.data[0]["FasciaName"],
            address1: data.data[0]["Address1"],
            address2: data.data[0]["Address2"],
            cityId: data.data[0]["CityID"],
            cityName: data.data[0]["City"],
            stateName: data.data[0]["State"],
            pinCode: data.data[0]["Pin"],
            inchargeFirstName: data.data[0]["PersonIncharge"],
            designation: data.data[0]["Designation"],
            registerAlternateMobileNo: registerAlternateMobileNoList[1],
            registerTelephoneNo: registerTelephoneNoList[1],
            registerTelephoneNo1: registerTelephoneNo1List[1],
            registerTelefaxNo: data.data[0]["TeleFax"],
            countryNamePreRegisterMobileNo: registerMobileNoList[0],
            countryNamePreRegisterAlternateMobileNo:
              registerAlternateMobileNoList[0],
            registerTelephoneNoPre: registerTelephoneNoList[0],
            registerTelephoneNo1Pre: registerTelephoneNo1List[0],
            primaryEmailId: data.data[0]["PrimaryEmail"],
            secondaryEmailId: data.data[0]["SecondaryEmail"],
            website: data.data[0]["Website"],
            nameOfOwner: data.data[0]["Name_Of_CEO_MD_President_Owner"],
            lastName: data.data[0]["LastName"],
            ownerDesignation: data.data[0]["OwnerDesignation"],
            ownerMobileNo: ownerMobileNoList[1],
            ownerEmailId: data.data[0]["Email"],

            invLastNamePersonIncharge:
              data.data[0]["InvLastNamePersonIncharge"],
            lastNamePersonIncharge: data.data[0]["LastNamePersonIncharge"],

            isCorrespondenceDetails: data.data[0]["SameAsCorrespondence"],

            invoicecompanyName: data.data[0]["InvCompanyName"],
            invoicecompanyId: data.data[0]["InvCompanyID"],
            invoiceaddress1: data.data[0]["InvAddress1"],
            invoiceaddress2: data.data[0]["InvAddress2"],
            invoicecityId: data.data[0]["InvCityID"],
            invoicecityName: data.data[0]["InvCity"],
            invoicestateId: data.data[0]["InvStateID"],
            invoicestateName: data.data[0]["InvState"],
            invoicepinCode: data.data[0]["InvPin"],
            invoiceinchargeFirstName: data.data[0]["InvPersonIncharge"],
            invoicedesignation: data.data[0]["InvDesignation"],
            invoiceregisterMobileNo: invoiceregisterMobileNoList[1],
            invoiceregisterAlternateMobileNo:
              invoiceregisterAlternateMobileNoList[1],
            invoiceregisterTelephoneNo: invoiceregisterTelephoneNoList[1],
            invoiceregisterTelephoneNo1: invoiceregisterTelephoneNo1List[1],
            countryNamePreInvoiceregisterMobileNo:
              invoiceregisterMobileNoList[0],
            countryNamePreInvoiceregisterAlternateMobileNo:
              invoiceregisterAlternateMobileNoList[0],
            invoiceregisterTelephoneNoPre: invoiceregisterTelephoneNoList[0],
            invoiceregisterTelephoneNo1Pre: invoiceregisterTelephoneNo1List[0],
            invoiceregisterTelefaxNo: data.data[0]["InvTeleFax"],
            invoiceprimaryEmailId: data.data[0]["InvPrimaryEmail"],
            invoicesecondaryEmailId: data.data[0]["InvSecondaryEmail"],
            invoicewebsite: data.data[0]["InvWebsite"],
            invoicecountryName: data.data[0]["InvCountryName"],
            invoicecountryid: data.data[0]["InvCountryID"] ?? "",
            compInfoNameOnPanCard: data.data[0]["NameOnPanCard"],
            compInfoPanNo: data.data[0]["PanNo"],
            compInfoTAN: data.data[0]["Tan"],
            compInfoGstNo: data.data[0]["GSTNo"],
            compInfoRegistrationNo: data.data[0]["CompanyRegistrationNo"],
            compInfoIecCode: data.data[0]["IECCode"],
            bankDtlAccountName: data.data[0]["AccountName"],
            bankDtlAccountNo: data.data[0]["AccountNo"],
            bankDtlAccountType: data.data[0]["AccountType"],
            bankDtlBankName: data.data[0]["BankName"],
            bankDtlBranchName: data.data[0]["BranchName"],
            bankDtlIfscCode: data.data[0]["SwiftCode_IFSCCode"],
            isCountryIndia: data.data.length > 0 && data.data[0].CountryID == 91
              ? true
              : false,

            title_InvPersonIncharge:
              data.data[0]["Title_InvPersonIncharge"] != null &&
                data.data[0]["Title_InvPersonIncharge"] != "" &&
                namePrefix.includes(data.data[0]["Title_InvPersonIncharge"])
                ? data.data[0]["Title_InvPersonIncharge"]
                : "Other",
            title_inchargeFirstName:
              data.data[0]["Title_PersonIncharge"] != null &&
                data.data[0]["Title_PersonIncharge"] != "" &&
                namePrefix.includes(data.data[0]["Title_PersonIncharge"])
                ? data.data[0]["Title_PersonIncharge"]
                : "Other",
            title_name_Of_CEO_MD_President_Owner:
              data.data[0]["Title_Name_Of_CEO_MD_President_Owner"] != null &&
                data.data[0]["Title_Name_Of_CEO_MD_President_Owner"] != "" &&
                namePrefix.includes(
                  data.data[0]["Title_Name_Of_CEO_MD_President_Owner"]
                )
                ? data.data[0]["Title_Name_Of_CEO_MD_President_Owner"]
                : "Other",

            isOther_title_InvPersonIncharge:
              data.data[0]["Title_InvPersonIncharge"] != null &&
                data.data[0]["Title_InvPersonIncharge"] != "" &&
                namePrefix.includes(data.data[0]["Title_InvPersonIncharge"])
                ? false
                : true,
            isOther_title_inchargeFirstName:
              data.data[0]["Title_PersonIncharge"] != null &&
                data.data[0]["Title_PersonIncharge"] != "" &&
                namePrefix.includes(data.data[0]["Title_PersonIncharge"])
                ? false
                : true,
            isOther_title_name_Of_CEO_MD_President_Owner:
              data.data[0]["Title_Name_Of_CEO_MD_President_Owner"] != null &&
                data.data[0]["Title_Name_Of_CEO_MD_President_Owner"] != "" &&
                namePrefix.includes(
                  data.data[0]["Title_Name_Of_CEO_MD_President_Owner"]
                )
                ? false
                : true,

            other_title_InvPersonIncharge:
              data.data[0]["Title_InvPersonIncharge"] != "" &&
                data.data[0]["Title_InvPersonIncharge"] != null &&
                namePrefix.includes(data.data[0]["Title_InvPersonIncharge"])
                ? false
                : data.data[0]["Title_InvPersonIncharge"],
            other_title_inchargeFirstName:
              data.data[0]["Title_PersonIncharge"] != null &&
                data.data[0]["Title_PersonIncharge"] != "" &&
                namePrefix.includes(data.data[0]["Title_PersonIncharge"])
                ? false
                : data.data[0]["Title_PersonIncharge"],
            other_title_name_Of_CEO_MD_President_Owner:
              data.data[0]["Title_Name_Of_CEO_MD_President_Owner"] != null &&
                data.data[0]["Title_Name_Of_CEO_MD_President_Owner"] != "" &&
                namePrefix.includes(
                  data.data[0]["Title_Name_Of_CEO_MD_President_Owner"]
                )
                ? false
                : data.data[0]["Title_Name_Of_CEO_MD_President_Owner"],

            exhibitionID: sessionStorage.getItem("sessionExhibitionID"), //data.data[0]['ExhibitionID'],
            exhRegistrationID: sessionStorage.getItem("ExhRegistrationID"), //data.data[0]['ExhRegistrationID'],
          });
          setTimeout(() => {
            this.setState({ isLoading: false });
          }, 300);
        } else {
          this.setState({ isLoading: false });
          toastError(data.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAgentDetails() {
    this.setState({ isLoading: true });
    this.props
      .getAgentDetails_action(sessionStorage.getItem("agentCode"))
      .then((data) => {
        if (data.error != 1 && data.data != null && data.data.length > 0) {
          if (data.data[0]["IsSelfPaid"]) {
            var registerMobileNoList =
              data.data[0]["MobileNo"] != null && data.data[0]["MobileNo"] != ""
                ? data.data[0]["MobileNo"].split("-")
                : ["", ""];
            var registerAlternateMobileNoList =
              data.data[0]["AlternateMobileNo"] != null &&
                data.data[0]["AlternateMobileNo"] != ""
                ? data.data[0]["AlternateMobileNo"].split("-")
                : ["", ""];
            var registerTelephoneNoList =
              data.data[0]["Telephone"] != null &&
                data.data[0]["Telephone"] != ""
                ? data.data[0]["Telephone"].split("-")
                : ["", ""];
            var registerTelephoneNo1List =
              data.data[0]["Telephone1"] != null &&
                data.data[0]["Telephone1"] != ""
                ? data.data[0]["Telephone1"].split("-")
                : ["", ""];

            this.setState({
              invoicecompanyName: data.data[0]["CompanyName"],
              invoiceaddress1: data.data[0]["Address1"] ?? "",
              invoiceaddress2: data.data[0]["Address2"] ?? "",
              invoicecityName: data.data[0]["City"],
              invoicestateName: data.data[0]["State"] ?? "",
              invoicepinCode: data.data[0]["Pin"] ?? "",
              title_InvPersonIncharge:
                data.data[0]["Title_PersonIncharge"] ?? "",
              invoiceinchargeFirstName: data.data[0]["PersonIncharge"] ?? "",
              invLastNamePersonIncharge:
                data.data[0]["LastNamePersonIncharge"] ?? "",
              invoicedesignation: data.data[0]["Designation"] ?? "",
              invoiceregisterMobileNo: registerMobileNoList[1],
              invoiceregisterAlternateMobileNo:
                registerAlternateMobileNoList[1],
              invoiceregisterTelephoneNo: registerTelephoneNoList[1],
              invoiceregisterTelephoneNo1: registerTelephoneNo1List[1],
              invoiceregisterTelefaxNo: data.data[0]["TeleFax"] ?? "",
              invoiceprimaryEmailId: data.data[0]["PrimaryEmail"] ?? "",
              invoicesecondaryEmailId: data.data[0]["SecondaryEmail"] ?? "",
              invoicewebsite: data.data[0]["Website"] ?? "",
              invoicecountryName: data.data[0]["CountryName"] ?? "",
              invoicecountryid: data.data[0]["CountryID"] ?? "",
              countryNamePreRegisterMobileNo: registerMobileNoList[0],
              countryNamePreRegisterAlternateMobileNo:
                registerAlternateMobileNoList[0],
              registerTelephoneNoPre: registerTelephoneNoList[0],
              registerTelephoneNo1Pre: registerTelephoneNo1List[0],
              isSelfPaid: true,
            });
          } else {
            const { countryName, countryId } = this.state;
            this.setState({
              invoicecountryName: countryName,
              invoicecountryid: countryId,
            });
          }

          setTimeout(() => {
            this.setState({ isLoading: false });
          }, 300);
        } else {
          this.setState({ isLoading: false });
          toastError(data.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSendRegData() {
    const {
      isLoading,
      isFirstStepCompleted,
      isCorrespondenceDetails,
      countryId,
      countryName,
      registerMobileNo,
      registerMobileNoErr,
      companyName,
      companyId,
      fasciaName,
      address1,
      address2,
      cityId,
      cityName,
      stateId,
      stateName,
      pinCode,
      inchargeFirstName,
      designation,
      registerAlternateMobileNo,
      registerTelephoneNo,
      registerTelephoneNo1,
      registerTelefaxNo,
      primaryEmailId,
      secondaryEmailId,
      website,
      nameOfOwner,
      lastName,
      ownerDesignation,
      ownerMobileNo,
      ownerEmailId,

      companyNameErr,
      fasciaNameErr,
      address1Err,
      cityNameErr,
      stateNameErr,
      pinCodeErr,
      countryNameErr,
      inchargeFirstNameErr,
      designationErr,
      registerAlternateMobileNoErr,
      registerTelephoneNoErr,
      registerTelefaxNoErr,
      primaryEmailIdErr,
      secondaryEmailIdErr,
      websiteErr,
      nameOfOwnerErr,

      lastNameErr,
      ownerMobileNoErr,
      ownerEmailIdErr,

      invoicecompanyName,
      invoicecompanyId,
      invoicecountryName,
      invoicecountryid,
      invoicefasciaName,
      invoiceaddress1,
      invoiceaddress2,
      invoicecityId,
      invoicecityName,
      invoicestateId,
      invoicestateName,
      invoicepinCode,
      invoiceinchargeFirstName,
      invoicedesignation,
      invoiceregisterMobileNo,
      invoiceregisterAlternateMobileNo,
      invoiceregisterTelephoneNo,
      invoiceregisterTelephoneNo1,
      invoiceregisterTelefaxNo,
      invoiceprimaryEmailId,
      invoicesecondaryEmailId,
      invoicewebsite,
      invoicenameOfOwner,
      invoicelastName,
      invoiceownerMobileNo,
      invoiceownerEmailId,

      invoicecompanyNameErr,
      invoicecompanyIdErr,
      invoicefasciaNameErr,
      invoiceaddress1Err,
      invoiceaddress2Err,
      invoicecityIdErr,
      invoicecityNameErr,
      invoicestateIdErr,
      invoicestateNameErr,
      invoicepinCodeErr,
      invoiceinchargeFirstNameErr,
      invoicedesignationErr,
      invoiceregisterMobileNoErr,
      invoiceregisterAlternateMobileNoErr,
      invoiceregisterTelephoneNoErr,
      invoiceregisterTelefaxNoErr,
      invoiceprimaryEmailIdErr,
      invoicesecondaryEmailIdErr,
      invoicewebsiteErr,
      invoicenameOfOwnerErr,
      invoicelastNameErr,
      invoiceownerMobileNoErr,
      invoiceownerEmailIdErr,
      invoicecountryNameErr,

      compInfoNameOnPanCard,
      compInfoPanNo,
      compInfoTAN,
      compInfoGstNo,
      compInfoRegistrationNo,
      compInfoIecCode,
      bankDtlAccountName,
      bankDtlAccountNo,
      bankDtlAccountType,
      bankDtlBankName,
      bankDtlBranchName,
      bankDtlIfscCode,

      compInfoNameOnPanCardErr,
      compInfoPanNoErr,
      compInfoTANErr,
      compInfoGstNoErr,
      compInfoRegistrationNoErr,
      compInfoIecCodeErr,
      bankDtlAccountNameErr,
      bankDtlAccountNoErr,
      bankDtlAccountTypeErr,
      bankDtlBankNameErr,
      bankDtlBranchNameErr,
      bankDtlIfscCodeErr,
      formFile,
      trcFile,
      declarationFile,
      isCountryIndia,
      exhibitionID,
      formFileType,
      declarationFileType,
      trcFileType,
      countryList,
      countryNamePreInvoiceregisterAlternateMobileNo,
      countryNamePreInvoiceregisterMobileNo,
      countryNamePreOwnerMobileNo,
      countryNamePreRegisterAlternateMobileNo,
      countryNamePreRegisterMobileNo,

      registerTelephoneNoPre,
      registerTelephoneNo1Pre,

      invoiceregisterTelephoneNoPre,
      invoiceregisterTelephoneNo1Pre,

      title_inchargeFirstName,
      title_name_Of_CEO_MD_President_Owner,
      title_InvPersonIncharge,

      isOther_title_InvPersonIncharge,
      isOther_title_inchargeFirstName,
      isOther_title_name_Of_CEO_MD_President_Owner,

      other_title_InvPersonIncharge,
      other_title_inchargeFirstName,
      other_title_name_Of_CEO_MD_President_Owner,

      invLastNamePersonIncharge,
      lastNamePersonIncharge,
      invLastNamePersonInchargeErr,
      lastNamePersonInchargeErr,
    } = this.state;

    // console.log("sunil...................", title_inchargeFirstName + " " + title_name_Of_CEO_MD_President_Owner + " " + title_InvPersonIncharge)
    sessionStorage.setItem(
      "sessionregisterTelephoneNoPre",
      registerTelephoneNoPre
    );
    sessionStorage.setItem(
      "sessionregisterTelephoneNo1Pre",
      registerTelephoneNo1Pre
    );
    sessionStorage.setItem(
      "sessioninvoiceregisterTelephoneNoPre",
      invoiceregisterTelephoneNoPre
    );
    sessionStorage.setItem(
      "sessioninvoiceregisterTelephoneNo1Pre",
      invoiceregisterTelephoneNo1Pre
    );
    sessionStorage.setItem(
      "sessionCountryNamePreRegisterMobileNo",
      countryNamePreRegisterMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreRegisterAlternateMobileNo",
      countryNamePreRegisterAlternateMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreOwnerMobileNo",
      countryNamePreOwnerMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreInvoiceregisterMobileNo",
      countryNamePreInvoiceregisterMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreInvoiceregisterAlternateMobileNo",
      countryNamePreInvoiceregisterAlternateMobileNo
    );
    console.log(compInfoGstNoErr, compInfoGstNo)
    var errMsg = "";
    // errMsg = contactNoValidator(Number(registerMobileNo))
    // errMsg = contactNoValidator(Number(registerAlternateMobileNo))
    //errMsg = contactNoValidator(Number(invoiceownerMobileNo))
    // errMsg = contactNoValidator(Number(invoiceregisterAlternateMobileNo))
    errMsg = errMsg == '' ? errMsg + panNoValidator(compInfoPanNo) : panNoValidator(compInfoPanNo)
    errMsg = errMsg == '' ? errMsg + tanNoValidator(compInfoTAN) : tanNoValidator(compInfoTAN)
    errMsg = errMsg == '' ? errMsg + gstNoValidator(compInfoGstNo) : gstNoValidator(compInfoGstNo)

    errMsg = errMsg == '' ? errMsg + emailValidator(primaryEmailId) : emailValidator(primaryEmailId)
    // errMsg = emailValidator(secondaryEmailId);
    errMsg = errMsg == '' ? errMsg + emailValidator(ownerEmailId) : emailValidator(ownerEmailId)
    errMsg = errMsg == '' ? errMsg + emailValidator(invoiceprimaryEmailId) : emailValidator(invoiceprimaryEmailId)

    var primaryAtcount = 0
    for (var i = 0; i < primaryEmailId.length; i++) {
      if (primaryEmailId[i] == '@') {
        primaryAtcount = primaryAtcount + 1
      }
    }
    var invPrimaryAtcount = 0
    for (var i = 0; i < invoiceprimaryEmailId.length; i++) {
      if (invoiceprimaryEmailId[i] == '@') {
        invPrimaryAtcount = invPrimaryAtcount + 1
      }
    }
    if (primaryAtcount > 1 || invPrimaryAtcount > 1) {
      errMsg = errMsg == '' ? errMsg + "Multiple email id not allowed inside primary email" : "Multiple email id not allowed inside primary email"
    }
    // errMsg = emailValidator(invoicesecondaryEmailId);
    console.log("errMsg", errMsg);

    this.inputValidate();
    var isContact = false;
    if (
      registerMobileNo != "" ||
      registerAlternateMobileNo != "" ||
      invoiceregisterMobileNo != "" ||
      invoiceregisterAlternateMobileNo != ""
    ) {
      isContact = true;
    } else {
      isContact = false;
    }
    // console.log("isContact blank contact", isContact)

    // validation for company info
    var isNotCompanyValidate = false;
    if (isCountryIndia) {
      if (compInfoGstNoErr != '') {
        console.log('here', compInfoGstNoErr)
        isNotCompanyValidate = true;
      }
      else {
        if (
          compInfoNameOnPanCard != "" &&
          compInfoPanNo != "" &&
          compInfoTAN != "" &&
          compInfoGstNo != ""
        ) {
          isNotCompanyValidate = false;
        } else {
          console.log('here', compInfoGstNoErr)
          isNotCompanyValidate = true;
        }
      }
    }
    // console.log("isNotCompanyValidate  blank contact", isNotCompanyValidate)
    var isNotStaBankValidate = false;
    if (isCountryIndia) {
      if (
        stateName != "" &&
        invoicestateName != ""
        // &&
        // bankDtlAccountName != "" &&
        // bankDtlAccountNo != "" &&
        // bankDtlIfscCode != ""
      ) {
        isNotStaBankValidate = false;
      } else {
        isNotStaBankValidate = true;
      }
    }

    if (
      lastNamePersonIncharge != "" &&
      invLastNamePersonIncharge != "" &&
      companyName != "" &&
      fasciaName != "" &&
      address1 != "" &&
      cityName != "" &&
      //  (sessionStorage.getItem("sessionContryName") == 'India ' && stateName != '') &&
      pinCode != "" &&
      countryName != "" &&
      inchargeFirstName != "" &&
      designation != "" &&
      registerMobileNo != "" &&
      primaryEmailId != "" &&
      nameOfOwner != "" &&
      this.state.ownerDesignation != "" &&
      ownerEmailId != "" &&
      invoicecompanyName != "" &&
      invoiceaddress1 != "" &&
      invoicecityName != "" &&
      // (sessionStorage.getItem("sessionContryName") == 'India ' &&  invoicestateName != '') &&
      invoicepinCode != "" &&
      invoiceinchargeFirstName != "" &&
      invoicedesignation != "" &&
      invoiceregisterMobileNo != "" &&
      invoiceprimaryEmailId != "" &&
      //    (sessionStorage.getItem("sessionContryName") == 'India ' &&  bankDtlAccountName != '') &&
      //    (sessionStorage.getItem("sessionContryName") == 'India ' &&  bankDtlAccountNo != '') &&
      //    (sessionStorage.getItem("sessionContryName") == 'India ' &&  bankDtlIfscCode != '' ) &&
      isContact &&
      isNotCompanyValidate == false &&
      isNotStaBankValidate == false
    ) {
      if (errMsg != "") {
        toastError(errMsg);
        errMsg = "";
      } else {
        console.log("entered");

        if (
          (lastNamePersonInchargeErr == "" &&
            invLastNamePersonInchargeErr == "" &&
            companyNameErr == "" &&
            fasciaNameErr == "" &&
            address1Err == "" &&
            cityNameErr == "" &&
            stateNameErr == "" &&
            pinCodeErr == "" &&
            countryNameErr == "" &&
            inchargeFirstNameErr == "" &&
            designationErr == "" &&
            registerAlternateMobileNoErr == "") ||
          (registerAlternateMobileNoErr ==
            "Please Enter proper contact number" &&
            primaryEmailIdErr == "" &&
            nameOfOwnerErr == "" &&
            ownerEmailIdErr == "" &&
            this.state.ownerDesignationErr == "" &&
            invoicecompanyNameErr == "" &&
            invoiceaddress1Err == "" &&
            invoicecityNameErr == "" &&
            invoicestateNameErr == "" &&
            invoicepinCodeErr == "" &&
            invoicecountryNameErr == "" &&
            invoiceinchargeFirstNameErr == "" &&
            invoicedesignationErr == "" &&
            invoiceownerMobileNoErr == "" &&
            invoiceprimaryEmailIdErr == "" &&
            bankDtlAccountNameErr == "" &&
            bankDtlAccountNameErr == "" &&
            bankDtlIfscCodeErr == ""
          )
        ) {
          this.setState({ isLoading: true });
          if (isCountryIndia ? (compInfoPanNoErr == '' && compInfoTANErr == '' && compInfoGstNoErr == '') : true) {
            this.props
              .registrationOneAdd_action(
                countryId,
                countryName,
                registerMobileNo,
                companyName,
                companyId,
                fasciaName,
                address1,
                address2,
                cityId,
                cityName,
                stateId,
                stateName,
                pinCode,
                inchargeFirstName,
                designation,
                registerAlternateMobileNo,
                registerTelephoneNo,
                registerTelephoneNo1,
                registerTelefaxNo,
                primaryEmailId,
                secondaryEmailId,
                website,
                nameOfOwner,
                lastName,
                ownerMobileNo,
                this.state.ownerDesignation,
                ownerEmailId,

                invoicecompanyName,
                invoicecompanyId,
                invoicecountryName,
                invoicecountryid,
                invoicefasciaName,
                invoiceaddress1,
                invoiceaddress2,
                invoicecityId,
                invoicecityName,
                invoicestateId,
                invoicestateName,
                invoicepinCode,
                invoiceinchargeFirstName,
                invoicedesignation,
                invoiceregisterMobileNo,
                invoiceregisterAlternateMobileNo,
                invoiceregisterTelephoneNo,
                invoiceregisterTelephoneNo1,
                invoiceregisterTelefaxNo,
                invoiceprimaryEmailId,
                invoicesecondaryEmailId,
                invoicewebsite,
                invoicenameOfOwner,
                invoicelastName,
                invoiceownerMobileNo,
                invoiceownerEmailId,

                compInfoNameOnPanCard,
                compInfoPanNo,
                compInfoTAN,
                compInfoGstNo,
                compInfoRegistrationNo,
                compInfoIecCode,
                bankDtlAccountName,
                bankDtlAccountNo,
                bankDtlAccountType,
                bankDtlBankName,
                bankDtlBranchName,
                bankDtlIfscCode,
                isCorrespondenceDetails,
                formFile,
                trcFile,
                declarationFile,
                exhibitionID,
                formFileType,
                declarationFileType,
                trcFileType,
                isOther_title_inchargeFirstName
                  ? other_title_inchargeFirstName
                  : title_inchargeFirstName,
                isOther_title_name_Of_CEO_MD_President_Owner
                  ? other_title_name_Of_CEO_MD_President_Owner
                  : title_name_Of_CEO_MD_President_Owner,
                isOther_title_InvPersonIncharge
                  ? other_title_InvPersonIncharge
                  : title_InvPersonIncharge,
                invLastNamePersonIncharge,
                lastNamePersonIncharge
              )
              .then((data) => {
                this.setState({ isLoading: false });
                if (data.error != 1) {
                  toastSuccess("Data saved successfully");
                  sessionStorage.setItem(
                    "sessionExhRegistrationID",
                    data.ExhRegistrationID
                  );
                  sessionStorage.setItem(
                    "sessionExhRegistrationNo",
                    data.ExhRegistrationNo
                  );

                  sessionStorage.setItem("renderPage", "primaryChapter");
                  toastSuccess("Data saved successfully");
                  // this.setState({ isLoading: false })
                  this.props.navigate("/primary_chapter", {
                    state: {
                      countryId: Number(countryId),
                      countryName: countryList.filter(
                        (x) => x.Code == countryId
                      )[0].Description,
                      is_update: false,
                    },
                  });
                } else {
                  // this.setState({ isLoading: false })
                  toastError(data.msg);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            if (isCountryIndia) {
              if (compInfoPanNoErr != "") {
                toastError(compInfoPanNoErr)
              } else if (compInfoTANErr != "") {
                toastError(compInfoTANErr)
              } else if (compInfoGstNoErr != "") {
                toastError(compInfoGstNoErr)
              }
            }
          }
        } else {
          toastError("Enter mandatory fields");
        }
      }
    } else {
      console.log("0909999=++++");
      toastError("Enter mandatory fields");
    }
  }

  onGSTchange(value) {
    const { compInfoGstNo, compInfoGstNoErr, gstValidtionList, stateId } = this.state
    var code = value
    console.log(code.length, 'rere')
    if (code.length >= 2) {
      var code = value.slice(0, 2)
      console.log(code.length, gstValidtionList, 'rere')
      console.log('stateId', stateId)
      for (var i = 0; i < gstValidtionList.length; i++) {
        if (gstValidtionList[i].StateID == stateId) {
          const errMsg = gstNoValidator(value)
          console.log("errMsg-->", errMsg)
          if (errMsg != '') {
            this.setState({
              compInfoGstNoErr: "Please enter proper gst no",
              // compInfoGstNo: ''
            });
          } else if (parseInt(gstValidtionList[i].GSTCode) != code) {
            console.log("2--->",)
            this.setState({
              compInfoGstNoErr: "Please enter proper gst no",
              // compInfoGstNo: ''
            });
          } else {
            this.setState({
              compInfoGstNo: value,
              compInfoGstNoErr: ''
            })
          }
        } else {
          this.setState({
            compInfoGstNo: value,
          });
        }
        // console.log(code, gstValidtionList[i].StateID, stateId, parseInt(gstValidtionList[i].GSTCode))
      }
    }
    else {
      this.setState({
        compInfoGstNo: value,
      });
    }
  }

  onSendRegDataUpdate() {
    const {
      isLoading,
      isFirstStepCompleted,
      isCorrespondenceDetails,
      countryId,
      countryName,
      registerMobileNo,
      registerMobileNoErr,
      companyName,
      companyId,
      fasciaName,
      address1,
      address2,
      cityId,
      cityName,
      stateId,
      stateName,
      pinCode,
      inchargeFirstName,
      designation,
      registerAlternateMobileNo,
      registerTelephoneNo,
      registerTelephoneNo1,
      registerTelefaxNo,
      primaryEmailId,
      secondaryEmailId,
      website,
      nameOfOwner,
      lastName,
      ownerMobileNo,
      ownerEmailId,

      companyNameErr,
      fasciaNameErr,
      address1Err,
      cityNameErr,
      stateNameErr,
      pinCodeErr,
      countryNameErr,
      inchargeFirstNameErr,
      designationErr,
      registerAlternateMobileNoErr,
      registerTelephoneNoErr,
      registerTelefaxNoErr,
      primaryEmailIdErr,
      secondaryEmailIdErr,
      websiteErr,
      nameOfOwnerErr,

      lastNameErr,
      ownerMobileNoErr,
      ownerEmailIdErr,
      ownerDesignationErr,

      invoicecompanyName,
      invoicecompanyId,
      invoicecountryName,
      invoicecountryid,
      invoicefasciaName,
      invoiceaddress1,
      invoiceaddress2,
      invoicecityId,
      invoicecityName,
      invoicestateId,
      invoicestateName,
      invoicepinCode,
      invoiceinchargeFirstName,
      invoicedesignation,
      invoiceregisterMobileNo,
      invoiceregisterAlternateMobileNo,
      invoiceregisterTelephoneNo,
      invoiceregisterTelephoneNo1,
      invoiceregisterTelefaxNo,
      invoiceprimaryEmailId,
      invoicesecondaryEmailId,
      invoicewebsite,
      invoicenameOfOwner,
      invoicelastName,
      invoiceownerMobileNo,
      invoiceownerEmailId,

      invoicecompanyNameErr,
      invoicecompanyIdErr,
      invoicefasciaNameErr,
      invoiceaddress1Err,
      invoiceaddress2Err,
      invoicecityIdErr,
      invoicecityNameErr,
      invoicestateIdErr,
      invoicestateNameErr,
      invoicepinCodeErr,
      invoiceinchargeFirstNameErr,
      invoicedesignationErr,
      invoiceregisterMobileNoErr,
      invoiceregisterAlternateMobileNoErr,
      invoiceregisterTelephoneNoErr,
      invoiceregisterTelefaxNoErr,
      invoiceprimaryEmailIdErr,
      invoicesecondaryEmailIdErr,
      invoicewebsiteErr,
      invoicenameOfOwnerErr,
      invoicelastNameErr,
      invoiceownerMobileNoErr,
      invoiceownerEmailIdErr,
      invoicecountryNameErr,

      compInfoNameOnPanCard,
      compInfoPanNo,
      compInfoTAN,
      compInfoGstNo,
      compInfoRegistrationNo,
      compInfoIecCode,
      bankDtlAccountName,
      bankDtlAccountNo,
      bankDtlAccountType,
      bankDtlBankName,
      bankDtlBranchName,
      bankDtlIfscCode,

      compInfoNameOnPanCardErr,
      compInfoPanNoErr,
      compInfoTANErr,
      compInfoGstNoErr,
      compInfoRegistrationNoErr,
      compInfoIecCodeErr,
      bankDtlAccountNameErr,
      bankDtlAccountNoErr,
      bankDtlAccountTypeErr,
      bankDtlBankNameErr,
      bankDtlBranchNameErr,
      bankDtlIfscCodeErr,
      formFile,
      trcFile,
      declarationFile,
      exhRegistrationID,
      exhibitionID,
      exhRegistrationNo,
      exhRegistrationDate,
      isCountryIndia,

      title_inchargeFirstName,
      title_name_Of_CEO_MD_President_Owner,
      title_InvPersonIncharge,
      formFileType,
      declarationFileType,
      trcFileType,
      invLastNamePersonIncharge,
      lastNamePersonIncharge,
      invLastNamePersonInchargeErr,
      lastNamePersonInchargeErr,
      bankDtlAccountTypeOthers,

      isOther_title_InvPersonIncharge,
      isOther_title_inchargeFirstName,
      isOther_title_name_Of_CEO_MD_President_Owner,

      other_title_InvPersonIncharge,
      other_title_inchargeFirstName,
      other_title_name_Of_CEO_MD_President_Owner,
      registerTelephoneNoPre,
      registerTelephoneNo1Pre,
      invoiceregisterTelephoneNoPre,
      invoiceregisterTelephoneNo1Pre,
      countryNamePreRegisterMobileNo,
      countryNamePreRegisterAlternateMobileNo,
      countryNamePreOwnerMobileNo,
      countryNamePreInvoiceregisterMobileNo,
      countryNamePreInvoiceregisterAlternateMobileNo,
    } = this.state;
    sessionStorage.setItem(
      "sessionregisterTelephoneNoPre",
      registerTelephoneNoPre
    );
    sessionStorage.setItem(
      "sessionregisterTelephoneNo1Pre",
      registerTelephoneNo1Pre
    );
    sessionStorage.setItem(
      "sessioninvoiceregisterTelephoneNoPre",
      invoiceregisterTelephoneNoPre
    );
    sessionStorage.setItem(
      "sessioninvoiceregisterTelephoneNo1Pre",
      invoiceregisterTelephoneNo1Pre
    );
    sessionStorage.setItem(
      "sessionCountryNamePreRegisterMobileNo",
      countryNamePreRegisterMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreRegisterAlternateMobileNo",
      countryNamePreRegisterAlternateMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreOwnerMobileNo",
      countryNamePreOwnerMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreInvoiceregisterMobileNo",
      countryNamePreInvoiceregisterMobileNo
    );
    sessionStorage.setItem(
      "sessionCountryNamePreInvoiceregisterAlternateMobileNo",
      countryNamePreInvoiceregisterAlternateMobileNo
    );

    var errMsg = "";
    // errMsg = contactNoValidator(Number(registerMobileNo))
    // errMsg = contactNoValidator(Number(registerAlternateMobileNo))
    // errMsg = contactNoValidator(Number(invoiceownerMobileNo))
    // errMsg = contactNoValidator(Number(invoiceregisterAlternateMobileNo))

    errMsg = errMsg == '' ? errMsg + panNoValidator(compInfoPanNo) : panNoValidator(compInfoPanNo)
    errMsg = errMsg == '' ? errMsg + tanNoValidator(compInfoTAN) : tanNoValidator(compInfoTAN)
    errMsg = errMsg == '' ? errMsg + gstNoValidator(compInfoGstNo) : gstNoValidator(compInfoGstNo)

    errMsg = errMsg == '' ? errMsg + emailValidator(primaryEmailId) : emailValidator(primaryEmailId)
    // errMsg = emailValidator(secondaryEmailId);
    errMsg = errMsg == '' ? errMsg + emailValidator(ownerEmailId) : emailValidator(ownerEmailId)
    errMsg = errMsg == '' ? errMsg + emailValidator(invoiceprimaryEmailId) : emailValidator(invoiceprimaryEmailId)



    var primaryAtcount = 0
    for (var i = 0; i < primaryEmailId.length; i++) {
      if (primaryEmailId[i] == '@') {
        primaryAtcount = primaryAtcount + 1
      }
    }
    var invPrimaryAtcount = 0
    for (var i = 0; i < invoiceprimaryEmailId.length; i++) {
      if (invoiceprimaryEmailId[i] == '@') {
        invPrimaryAtcount = invPrimaryAtcount + 1
      }
    }
    if (primaryAtcount > 1 || invPrimaryAtcount > 1) {
      errMsg = errMsg == '' ? errMsg + "Multiple email id not allowed inside primary email" : "Multiple email id not allowed inside primary email"
    }
    // errMsg = emailValidator(invoicesecondaryEmailId);

    this.inputValidate();
    var isnotContact = false;
    // console.log('abc :',registerMobileNo+" "+registerAlternateMobileNo+" "+invoiceownerMobileNo+" "+invoiceregisterAlternateMobileNo);
    if (registerMobileNo != "") {
      isnotContact = false;
    } else {
      isnotContact = true;
    }

    // validation for company info
    var isNotCompanyValidate = false;
    if (isCountryIndia) {
      if (compInfoGstNoErr != '') {
        console.log('here', compInfoGstNoErr)
        isNotCompanyValidate = true;
      } else {
        if (
          compInfoNameOnPanCard != "" &&
          compInfoPanNo != "" &&
          compInfoTAN != "" &&
          compInfoGstNo != ""
        ) {
          isNotCompanyValidate = false;
        } else {
          isNotCompanyValidate = true;
        }
      }
    }
    console.log("isNotCompanyValidate  blank contact", isNotCompanyValidate);
    var isNotStaBankValidate = false;
    if (isCountryIndia) {
      if (
        stateName != "" &&
        invoicestateName != ""
        //  &&
        // bankDtlAccountName != "" &&
        // bankDtlAccountNo != "" &&
        // bankDtlIfscCode != ""
      ) {
        isNotStaBankValidate = false;
      } else {
        isNotStaBankValidate = true;
      }
    }

    if (
      lastNamePersonIncharge != "" &&
      invLastNamePersonIncharge != "" &&
      companyName != "" &&
      fasciaName != "" &&
      address1 != "" &&
      cityName != "" &&
      // (sessionStorage.getItem("sessionContryName") == 'India ' &&  stateName != '') &&
      pinCode != "" &&
      countryName != "" &&
      inchargeFirstName != "" &&
      designation != "" &&
      registerMobileNo != "" &&
      primaryEmailId != "" &&
      nameOfOwner != "" &&
      this.state.ownerDesignation != "" &&
      ownerEmailId != "" &&
      invoicecompanyName != "" &&
      invoiceaddress1 != "" &&
      invoicecityName != "" &&
      // (sessionStorage.getItem("sessionContryName") == 'India ' &&  invoicestateName != '') &&
      invoicepinCode != "" &&
      invoicecountryName != "" &&
      invoiceinchargeFirstName != "" &&
      invoicedesignation != "" &&
      invoiceregisterMobileNo != "" &&
      invoiceprimaryEmailId != "" &&
      // (sessionStorage.getItem("sessionContryName") == 'India ' &&  bankDtlAccountName != '') &&
      // (sessionStorage.getItem("sessionContryName") == 'India ' &&  bankDtlAccountNo != '') &&
      // (sessionStorage.getItem("sessionContryName") == 'India ' &&  bankDtlIfscCode != '' ) &&
      !isnotContact &&
      isNotCompanyValidate == false &&
      isNotStaBankValidate == false
    ) {
      console.log("error msg : ", errMsg);
      if (errMsg != "") {
        toastError(errMsg);
        errMsg = "";
      } else {
        if (
          (lastNamePersonInchargeErr == "" &&
            invLastNamePersonInchargeErr == "" &&
            companyNameErr == "" &&
            fasciaNameErr == "" &&
            address1Err == "" &&
            cityNameErr == "" &&
            stateNameErr == "" &&
            pinCodeErr == "" &&
            countryNameErr == "" &&
            inchargeFirstNameErr == "" &&
            designationErr == "" &&
            registerMobileNoErr == "") ||
          (registerMobileNoErr == "Please Enter proper contact number" &&
            primaryEmailIdErr == "" &&
            nameOfOwnerErr == "" &&
            ownerEmailIdErr == "" &&
            this.state.ownerDesignation == "" &&
            invoicecompanyNameErr == "" &&
            invoiceaddress1Err == "" &&
            invoicecityNameErr == "" &&
            invoicestateNameErr == "" &&
            invoicepinCodeErr == "" &&
            invoicecountryNameErr == "" &&
            invoiceinchargeFirstNameErr == "" &&
            invoicedesignationErr == "" &&
            invoiceownerMobileNoErr == "" &&
            invoiceprimaryEmailIdErr == "" &&
            bankDtlAccountNameErr == "" &&
            bankDtlAccountNameErr == "" &&
            bankDtlIfscCodeErr == "")
        ) {

          // this.setState({ exhRegistrationID: this.props.location.state.exhRegistrationID, exhibitionID: 1 }) // need to replace with dynamic data
          if (isCountryIndia ? (compInfoPanNoErr == '' && compInfoTANErr == '' && compInfoGstNoErr == '') : true) {
            this.setState({ isLoading: true });
            this.props
              .registrationOneUpdate_action(
                countryId,
                countryName,
                registerMobileNo,
                companyName,
                companyId,
                fasciaName,
                address1,
                address2,
                cityId,
                cityName,
                stateId,
                stateName,
                pinCode,
                inchargeFirstName,
                designation,
                registerAlternateMobileNo,
                registerTelephoneNo,
                registerTelephoneNo1,
                registerTelefaxNo,
                primaryEmailId,
                secondaryEmailId,
                website,
                nameOfOwner,
                lastName,
                ownerMobileNo,
                this.state.ownerDesignation,
                ownerEmailId,

                invoicecompanyName,
                invoicecompanyId,
                invoicecountryName,
                invoicecountryid,
                invoicefasciaName,
                invoiceaddress1,
                invoiceaddress2,
                invoicecityId,
                invoicecityName,
                invoicestateId,
                invoicestateName,
                invoicepinCode,
                invoiceinchargeFirstName,
                invoicedesignation,
                invoiceregisterMobileNo,
                invoiceregisterAlternateMobileNo,
                invoiceregisterTelephoneNo,
                invoiceregisterTelephoneNo1,
                invoiceregisterTelefaxNo,
                invoiceprimaryEmailId,
                invoicesecondaryEmailId,
                invoicewebsite,
                invoicenameOfOwner,
                invoicelastName,
                invoiceownerMobileNo,
                invoiceownerEmailId,

                compInfoNameOnPanCard,
                compInfoPanNo,
                compInfoTAN,
                compInfoGstNo,
                compInfoRegistrationNo,
                compInfoIecCode,
                bankDtlAccountName,
                bankDtlAccountNo,
                bankDtlAccountType == "Others"
                  ? bankDtlAccountTypeOthers
                  : bankDtlAccountType,
                bankDtlBankName,
                bankDtlBranchName,
                bankDtlIfscCode,
                isCorrespondenceDetails,
                formFile,
                trcFile,
                declarationFile,
                exhibitionID,
                formFileType,
                declarationFileType,
                trcFileType,
                isOther_title_inchargeFirstName
                  ? other_title_inchargeFirstName
                  : title_inchargeFirstName,
                isOther_title_name_Of_CEO_MD_President_Owner
                  ? other_title_name_Of_CEO_MD_President_Owner
                  : title_name_Of_CEO_MD_President_Owner,
                isOther_title_InvPersonIncharge
                  ? other_title_InvPersonIncharge
                  : title_InvPersonIncharge,
                invLastNamePersonIncharge,
                lastNamePersonIncharge
              )
              .then((data) => {
                if (data.error != 1) {
                  console.log(data.data[0]["exhibitorID"]);
                  // toastSuccess("Data Update successfully");
                  const { is_update } = this.state;
                  setTimeout(() => {
                    this.setState({ isLoading: false });
                    this.props.navigate("/primary_chapter", {
                      state: {
                        exhibitorID: data.data[0]["exhibitorID"],
                        is_update: true,
                      },
                    });
                  }, 300);
                } else {
                  this.setState({ isLoading: false });
                  toastError(data.msg);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            if (isCountryIndia) {
              if (compInfoPanNoErr != "") {
                toastError(compInfoPanNoErr)
              } else if (compInfoTANErr != "") {
                toastError(compInfoTANErr)
              } else if (compInfoGstNoErr != "") {
                toastError(compInfoGstNoErr)
              }
            }
          }
        } else {
          console.log("error msg : 1",);
          toastError("Enter mandatory fields");
        }
      }
    } else {
      console.log("error msg : 2",);
      toastError("Enter mandatory fields");
    }
  }

  onCorrespondenceDetailsClick() {
    const {
      isCorrespondenceDetails,
      countryName,
      registerMobileNo,
      registerMobileNoErr,
      countryId,
      companyName,
      companyId,
      fasciaName,
      address1,
      address2,
      cityId,
      cityName,
      stateId,
      stateName,
      pinCode,
      inchargeFirstName,
      designation,
      registerAlternateMobileNo,
      registerTelephoneNo,
      registerTelephoneNo1,
      registerTelefaxNo,
      primaryEmailId,
      secondaryEmailId,
      website,
      nameOfOwner,
      lastName,
      ownerMobileNo,
      ownerEmailId,

      invoicecompanyNameErr,
      invoicecompanyIdErr,
      invoicefasciaNameErr,
      invoiceaddress1Err,
      invoiceaddress2Err,
      invoicecityIdErr,
      invoicecityNameErr,
      invoicestateIdErr,
      invoicestateNameErr,
      invoicepinCodeErr,
      invoiceinchargeFirstNameErr,
      invoicedesignationErr,
      invoiceregisterMobileNoErr,
      invoiceregisterAlternateMobileNoErr,
      invoiceregisterTelephoneNoErr,
      invoiceregisterTelefaxNoErr,
      invoiceprimaryEmailIdErr,
      invoicesecondaryEmailIdErr,
      invoicewebsiteErr,
      invoicenameOfOwnerErr,
      invoicelastNameErr,
      invoiceownerMobileNoErr,
      invoiceownerEmailIdErr,
      invoicecountryNameErr,
      lastNamePersonIncharge,
    } = this.state;
    this.setState({ isCorrespondenceDetails: !isCorrespondenceDetails });
    setTimeout(() => {
      if (isCorrespondenceDetails == false) {
        this.setState({
          invoicecompanyName: companyName,
          invoicecountryName: countryName,
          invoicecountryid: countryId,
          invoiceregisterMobileNo: registerMobileNo,
          invoicefasciaName: fasciaName,
          invoiceaddress1: address1,
          invoiceaddress2: address2,
          invoicecityName: cityName,
          invoicestateName: stateName,
          invoicepinCode: pinCode,
          invoicedesignation: designation,
          invoiceinchargeFirstName: inchargeFirstName,
          invoiceregisterAlternateMobileNo: registerAlternateMobileNo,
          invoiceregisterTelephoneNo: registerTelephoneNo,
          invoiceregisterTelephoneNo1: registerTelephoneNo1,
          invoiceregisterTelefaxNo: registerTelefaxNo,

          invoiceprimaryEmailId: primaryEmailId,
          invoicesecondaryEmailId: secondaryEmailId,
          invoicewebsite: website,
          invoicenameOfOwner: nameOfOwner,
          invoicelastName: lastName,
          invoiceownerMobileNo: ownerMobileNo,
          invoiceownerEmailId: ownerEmailId,

          invLastNamePersonIncharge: lastNamePersonIncharge,

          invoicecompanyNameErr: "",
          invoiceaddress1Err: "",
          invoicecityIdErr: "",
          invoicecityNameErr: "",
          invoicestateIdErr: "",
          invoicestateNameErr: "",
          invoicepinCodeErr: "",
          invoicecountryNameErr: "",
          invoiceinchargeFirstNameErr: "",
          invoicedesignationErr: "",
          invoiceregisterMobileNoErr: "",
          invoiceprimaryEmailIdErr: "",
          invoicenameOfOwnerErr: "",
          invoicelastNameErr: "",
          invoiceownerMobileNoErr: "",
          invoiceownerEmailIdErr: "",
          invLastNamePersonInchargeErr: "",
        });
      }
    }, 300);

    setTimeout(() => {
      if (isCorrespondenceDetails == true) {
        this.setState({
          invoicecompanyName: "",
          invoicecountryName: "",
          invoiceregisterMobileNo: "",
          invoicefasciaName: "",
          invoiceaddress1: "",
          invoiceaddress2: "",
          invoicecityName: "",
          invoicestateName: "",
          invoicepinCode: "",
          invoicedesignation: "",
          invoiceinchargeFirstName: "",
          invoiceregisterAlternateMobileNo: "",
          invoiceregisterTelephoneNo: "",
          invoiceregisterTelephoneNo1: "",
          invoiceregisterTelefaxNo: "",

          invoiceprimaryEmailId: "",
          invoicesecondaryEmailId: "",
          invoicewebsite: "",
          invoicenameOfOwner: "",
          invoicelastName: "",
          invoiceownerMobileNo: "",
          invoiceownerEmailId: "",

          invoicecompanyNameErr: "",
          invoiceaddress1Err: "",
          invoicecityIdErr: "",
          invoicecityNameErr: "",
          invoicestateIdErr: "",
          invoicestateNameErr: "",
          invoicepinCodeErr: "",
          invoicecountryNameErr: "",
          invoiceinchargeFirstNameErr: "",
          invoicedesignationErr: "",
          invoiceregisterMobileNoErr: "",
          invoiceprimaryEmailIdErr: "",
          invoicenameOfOwnerErr: "",
          invoicelastNameErr: "",
          invoiceownerMobileNoErr: "",
          invoiceownerEmailIdErr: "",
        });
      }
    }, 300);
  }

  emailFocusOut(from) {
    const { primaryEmailId, secondaryEmailId, ownerEmailId } = this.state;

    if (from == "fromprimaryemail") {
      if (
        primaryEmailId != null &&
        primaryEmailId != "" &&
        emailValidator(primaryEmailId) == ""
      ) {
        this.setState({
          isPrimaryEmailId: true,
          isSecondaryEmailId: false,
          isOwenerEmailId: false,
        });
        this.getFillList(
          verifyPrimaryEmailIDActionId,
          sessionStorage.getItem("sessionExhibitionID"),
          "",
          "",
          primaryEmailId,
          ""
        );
      } else {
        toastError("Please enter proper primary email");
        this.setState({ primaryEmailId: "" });
      }
    }
    if (from == "fromsecondaryemail") {
      if (
        secondaryEmailId != null &&
        secondaryEmailId != "" &&
        emailValidator(secondaryEmailId) == ""
      ) {
        this.setState({
          isPrimaryEmailId: false,
          isSecondaryEmailId: true,
          isOwenerEmailId: false,
        });
        this.getFillList(
          verifyPrimaryEmailIDActionId,
          sessionStorage.getItem("sessionExhibitionID"),
          "",
          "",
          secondaryEmailId,
          ""
        );
      } else {
        toastError("Please enter proper secondary email");
        this.setState({ secondaryEmailId: "" });
      }
    }
    if (from == "fromowneremail") {
      if (
        ownerEmailId != null &&
        ownerEmailId != "" &&
        emailValidator(ownerEmailId) == ""
      ) {
        this.setState({
          isPrimaryEmailId: false,
          isSecondaryEmailId: false,
          isOwenerEmailId: true,
        });
        this.getFillList(
          verifyPrimaryEmailIDActionId,
          sessionStorage.getItem("sessionExhibitionID"),
          "",
          "",
          ownerEmailId,
          ""
        );
      } else {
        toastError("Please enter proper email");
        this.setState({ ownerEmailId: "" });
      }
    }
  }

  invemailFocusOut(from) {
    const { invoiceprimaryEmailId, invoicesecondaryEmailId } = this.state;
    if (from == "invoiceprimaryEmailId") {
      if (
        invoiceprimaryEmailId != null &&
        invoiceprimaryEmailId != "" &&
        emailValidator(invoiceprimaryEmailId) == ""
      ) {
        this.setState({
          isInvoiceprimaryEmailId: true,
          isInvoicesecondaryEmailId: false,
        });

        this.getFillList(
          verifyPrimaryEmailIDActionId,
          sessionStorage.getItem("sessionExhibitionID"),
          "",
          "",
          invoiceprimaryEmailId,
          ""
        );
      } else {
        toastError("Please enter proper email");
        this.setState({ invoiceprimaryEmailId: "" });
      }
    }
    if (from == "invoicesecondaryEmailId") {
      if (
        invoicesecondaryEmailId != null &&
        invoicesecondaryEmailId != "" &&
        emailValidator(invoicesecondaryEmailId) == ""
      ) {
        this.setState({
          isInvoiceprimaryEmailId: false,
          isInvoicesecondaryEmailId: true,
        });
        this.getFillList(
          verifyPrimaryEmailIDActionId,
          sessionStorage.getItem("sessionExhibitionID"),
          "",
          "",
          invoiceprimaryEmailId,
          ""
        );
      } else {
        toastError("Please enter proper email");
        this.setState({ invoicesecondaryEmailId: "" });
      }
    }
  }

  onNextClick() {
    const { is_update, exhRegistrationNo } = this.state;
    this.inputValidate();
    setTimeout(() => {
      console.log('is_update', is_update, exhRegistrationNo)
      if (is_update == true) {
        this.onSendRegDataUpdate();
      } else {
        this.onSendRegData();
      }
    }, 200);
  }

  onPreviousClick() {
    sessionStorage.getItem("sessionCode");
    console.log("sessionCode", sessionStorage.getItem("sessionCode"));
    this.props.navigate(`/${sessionStorage.getItem("sessionCode")}`);
  }

  onFilePick = async (e, file, field) => {
    e.preventDefault();
    const {
      formFile,
      trcFile,
      declarationFile,
      formFileType,
      declarationFileType,
      trcFileType,
    } = this.state;
    var image = "";
    console.log("type", file[0]["type"]);
    if (file[0]["size"] > 5242880) {
      toast.error("File size more than 5mb are not allow.", {
        theme: "colored",
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      if (
        file[0]["name"].toString().toLowerCase().includes(".pdf")
        // ||
        //     file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
        //     file[0]['name'].toString().toLowerCase().includes('.png') ||
        //     file[0]['name'].toString().toLowerCase().includes('.jpg')
      ) {
        if (file[0]["type"].includes("pdf")) {
          image = await this.onPdfSelect(file[0]);
        } else {
          image = await this.resizeFile(file[0]);
        }
        if (field == "formFile") {
          this.setState({ formFile: image, formFileType: file[0]["type"] });
        } else if (field == "trcFile") {
          this.setState({ trcFile: image, trcFileType: file[0]["type"] });
        } else if (field == "declarationFile") {
          this.setState({
            declarationFile: image,
            declarationFileType: file[0]["type"],
          });
        }
      } else {
        // Only .pdf , .jpeg, .png, .jpg files are allowed
        toast.error("Only .pdf files are allowed", {
          theme: "colored",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    }
  };

  onPdfSelect = (file) =>
    new Promise((resolve) => {
      let base64;
      // FileReader function for read the file.
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result;
        // Print data in console
        resolve(base64);
      };
      // Convert data to base64
      fileReader.readAsDataURL(file);
    });

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        600,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        300,
        300
      );
    });

  inputValidate() {
    const {
      countryId,
      countryName,
      registerMobileNo,
      registerMobileNoErr,
      countryNamePre,
      companyName,
      companyId,
      fasciaName,
      address1,
      address2,
      cityId,
      cityName,
      stateId,
      stateName,
      pinCode,
      inchargeFirstName,
      designation,
      registerAlternateMobileNo,
      registerTelephoneNo,
      registerTelephoneNo1,
      registerTelefaxNo,
      primaryEmailId,
      secondaryEmailId,
      website,
      nameOfOwner,
      lastName,
      ownerMobileNo,
      ownerEmailId, gstValidtionList,

      invoicecompanyName,
      invoicecompanyId,
      invoicefasciaName,
      invoiceaddress1,
      invoiceaddress2,
      invoicecityId,
      invoicecityName,
      invoicestateId,
      invoicestateName,
      invoicepinCode,
      invoiceinchargeFirstName,
      invoicedesignation,
      invoiceregisterMobileNo,
      invoiceregisterAlternateMobileNo,
      invoiceregisterTelephoneNo,
      invoiceregisterTelefaxNo,
      invoiceprimaryEmailId,
      invoicesecondaryEmailId,
      invoicewebsite,
      invoicenameOfOwner,
      invoicelastName,
      invoiceownerMobileNo,
      invoiceownerEmailId,
      invoicecountryName,

      compInfoNameOnPanCard,
      compInfoPanNo,
      compInfoTAN,
      compInfoGstNo,
      compInfoRegistrationNo,
      compInfoIecCode,
      bankDtlAccountName,
      bankDtlAccountNo,
      bankDtlAccountType,
      bankDtlBankName,
      bankDtlBranchName,
      bankDtlIfscCode,

      compInfoNameOnPanCardErr,
      isCountryIndia,
      invLastNamePersonIncharge,
      lastNamePersonIncharge,
      invLastNamePersonInchargeErr,
      lastNamePersonInchargeErr,

    } = this.state;

    if (lastNamePersonIncharge == "") {
      this.setState({
        lastNamePersonInchargeErr: "Please enter last name",
      });
    }

    if (invLastNamePersonIncharge == "") {
      this.setState({
        invLastNamePersonInchargeErr: "Please enter last name",
      });
    }

    if (companyName == "") {
      this.setState({
        companyNameErr: "Please enter company name",
      });
    }
    if (fasciaName == "") {
      this.setState({
        fasciaNameErr: "Please enter fascia name",
      });
    }
    if (address1 == "") {
      this.setState({
        address1Err: "Please enter address",
      });
    }

    if (registerMobileNo == "") {
      this.setState({
        registerMobileNoErr: "Please enter mobile number",
      });
    }

    if (cityName == "") {
      this.setState({
        cityNameErr: "Please enter city",
      });
    }

    if (
      stateName == "" &&
      sessionStorage.getItem("sessionContryName") == "India "
    ) {
      this.setState({
        stateNameErr: "Please enter state",
      });
    }

    if (pinCode == "") {
      this.setState({
        pinCodeErr: "Please enter pincode",
      });
    }

    if (countryName == "") {
      this.setState({
        countryNameErr: "Please select country",
      });
    }
    if (inchargeFirstName == "") {
      this.setState({
        inchargeFirstNameErr: "Please enter person incharge first name",
      });
    }
    if (designation == "") {
      this.setState({
        designationErr: "Please enter designation",
      });
    }
    if (primaryEmailId == "") {
      this.setState({
        primaryEmailIdErr: "Please enter primary email",
      });
    }
    if (nameOfOwner == "") {
      this.setState({
        nameOfOwnerErr: "Please enter name of owner",
      });
    }
    if (this.state.ownerDesignation == "") {
      this.setState({
        ownerDesignationErr: "Please enter owner Designation",
      });
    }
    if (ownerEmailId == "") {
      this.setState({
        ownerEmailIdErr: "Please enter email",
      });
    }

    // Invoice Details
    if (invoicecompanyName == "") {
      this.setState({
        invoicecompanyNameErr: "Please enter company name",
      });
    }
    if (invoicefasciaName == "") {
      this.setState({
        invoicefasciaNameErr: "Please enter fascia name",
      });
    }
    if (invoiceaddress1 == "") {
      this.setState({
        invoiceaddress1Err: "Please enter address",
      });
    }

    if (invoiceregisterMobileNo == "") {
      this.setState({
        invoiceregisterMobileNoErr: "Please enter mobile number",
      });
    }

    if (invoicecityName == "") {
      this.setState({
        invoicecityNameErr: "Please enter city",
      });
    }
    if (
      invoicestateName == "" &&
      sessionStorage.getItem("sessionContryName") == "India "
    ) {
      this.setState({
        invoicestateNameErr: "Please enter state",
      });
    }
    if (invoicepinCode == "") {
      this.setState({
        invoicepinCodeErr: "Please enter pincode",
      });
    }

    if (invoicecountryName == "") {
      this.setState({
        invoicecountryNameErr: "Please select country",
      });
    }
    if (invoiceinchargeFirstName == "") {
      this.setState({
        invoiceinchargeFirstNameErr: "Please enter person incharge first name",
      });
    }
    if (invoicedesignation == "") {
      this.setState({
        invoicedesignationErr: "Please enter designation",
      });
    }
    if (invoiceprimaryEmailId == "") {
      this.setState({
        invoiceprimaryEmailIdErr: "Please enter primary email",
      });
    }
    if (invoicenameOfOwner == "") {
      this.setState({
        invoicenameOfOwnerErr: "Please enter name of owner",
      });
    }
    if (invoiceownerEmailId == "") {
      this.setState({
        invoiceownerEmailIdErr: "Please enter email",
      });
    }

    // account details
    // if (
    //   sessionStorage.getItem("sessionContryName") == "India " &&
    //   bankDtlAccountName == ""
    // ) {
    //   this.setState({
    //     bankDtlAccountNameErr: "Please enter account name",
    //   });
    // }
    // if (
    //   sessionStorage.getItem("sessionContryName") == "India " &&
    //   bankDtlAccountNo == ""
    // ) {
    //   this.setState({
    //     bankDtlAccountNoErr: "Please enter account number",
    //   });
    // }
    // if (
    //   sessionStorage.getItem("sessionContryName") == "India " &&
    //   bankDtlIfscCode == ""
    // ) {
    //   this.setState({
    //     bankDtlIfscCodeErr: "Please enter IFSC Code",
    //   });
    // }

    if (isCountryIndia) {
      if (compInfoNameOnPanCard == "") {
        this.setState({
          compInfoNameOnPanCardErr: "Please enter name on pan card",
        });
      }
      if (compInfoPanNo == "") {
        this.setState({
          compInfoPanNoErr: "Please enter pan no",
        });
      }
      if (compInfoTAN == "") {
        this.setState({
          compInfoTANErr: "Please enter tan no",
        });
      }

      if (compInfoGstNo == "") {
        this.setState({
          compInfoGstNoErr: "Please enter gst no",
        });
      } else {
        var code = compInfoGstNo.slice(0, 2)
        for (var i = 0; i < gstValidtionList.length; i++) {
          if (gstValidtionList[i].StateID == stateId) {
            if (parseInt(gstValidtionList[i].GSTCode) != code) {
              this.setState({
                compInfoGstNoErr: "Please enter proper gst no",
              });
              console.log('ip', this.state.compInfoGstNoErr)

            }
          }
          // console.log(code, gstValidtionList[i].StateID, stateId, parseInt(gstValidtionList[i].GSTCode))
        }
      }
      // if (compInfoRegistrationNo == '') {
      //     this.setState({
      //         compInfoRegistrationNoErr: 'Please enter Company registration no'
      //     })
      // }
    }
  }

  preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  maxLengthCheck = (object, maxLength) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  onlyNumbers = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;
    return false;
  };

  // get fill List dropdown
  getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
    const {
      isPrimaryEmailId,
      isSecondaryEmailId,
      isOwenerEmailId,
      isInvoiceprimaryEmailId,
      isInvoicesecondaryEmailId,
    } = this.state;
    this.setState({ isLoading: true });
    this.props
      .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
      .then((data) => {
        if (data.error != 1) {
          // country list
          if (actionID === countryActionId) {
            console.log("country data", data.data);
            this.setState({
              countryList: data.data,
              isLoading: false,
            });
          }
          // state list
          if (actionID === stateActionId) {
            console.log("stateList data", data.data);
            this.setState({
              stateList: data.data,
              tempStateList: data.data,
              InvtempStateList: data.data,
              isLoading: false,
            });
          }

          // city list
          if (actionID === cityActionId) {
            console.log("city data", data.data);
            this.setState({
              cityList: data.data,
              tempCityList: data.data,
              InvtempCityList: data.data,
              isLoading: false,
            });
          }

          // pincode
          if (actionID === pincodeActionId) {
            // console.log("pincode data", data.data)
            this.setState({
              pincodeList: data.data,
              tempPincodeList: data.data,
              InvtempPincodeList: data.data,
              isLoading: false,
            });
          }

          // verify email existence
          if (actionID === verifyPrimaryEmailIDActionId) {
            // console.log("is Email exist", data.data)
            this.setState({
              isLoading: false,
            });

            if (data.data[0].Description != undefined) {
              if (from == "PrimaryEmailId") {
                this.setState({
                  primaryEmailId: "",
                });
              }
              if (from == "InvPrimaryEmailId") {
                this.setState({
                  invoiceprimaryEmailId: "",
                });
              }
            }

            if (isPrimaryEmailId) {
              this.setState({ isPrimaryEmailId: false, primaryEmailId: "" });
            } else if (isSecondaryEmailId) {
              this.setState({
                isSecondaryEmailId: false,
                secondaryEmailId: "",
              });
            } else if (isOwenerEmailId) {
              this.setState({ isOwenerEmailId: false, ownerEmailId: "" });
            }

            if (isInvoiceprimaryEmailId) {
              this.setState({
                isInvoiceprimaryEmailId: false,
                invoiceprimaryEmailId: "",
              });
            } else if (isInvoicesecondaryEmailId) {
              this.setState({
                isInvoicesecondaryEmailId: false,
                invoicesecondaryEmailId: "",
              });
            }

            toastError(data.data[0].Description);
          }

          if (actionID === GSTvalidationActionId) {
            console.log("country data", data.data)
            this.setState({
              gstValidtionList: data.data, isLoading: false,
            })
          }
        } else {
          this.setState({ isLoading: false });
          toastError(data.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  validateNumberField = (myNumber) => {
    const numberRegEx = /\-?\d*\.?\d{1,2}/;
    return numberRegEx.test(String(myNumber).toLowerCase());
  };

  changeUrl = (e) => {
    const { value } = e.target;
    const isValid = !value;
    console.log("isValid", isValid);
    this.setState({ pinCode: e.target.value, pinCodeErr: "" });
  };

  onChangePrimaryEmail(value, id) {
    var primaryAtcount = 0
    for (var i = 0; i < value.length; i++) {
      if (value[i] == '@') {
        primaryAtcount = primaryAtcount + 1
      }
    }
    if (primaryAtcount > 1) {
      console.log(id)
      {
        id == 1 &&
          this.setState({
            primaryEmailId: value,
            primaryEmailIdErr: "Multiple email id not allowed inside primary email"
          })
      }
      {
        id == 2 &&
          this.setState({
            invoiceprimaryEmailId: value,
            invoiceprimaryEmailIdErr: "Multiple email id not allowed inside primary email"
          })
      }
    } else {
      {
        id == 1 &&
          this.setState({
            primaryEmailId: value,
            primaryEmailIdErr: ''
          })
      }
      {
        id == 2 &&
          this.setState({
            invoiceprimaryEmailId: value,
            invoiceprimaryEmailIdErr: ''
          })
      }
    }
  }

  render() {
    const {
      isLoading,
      isCorrespondenceDetails,
      is_update,
      cityList,
      stateList,
      pincodeList,
      countryNamePre,
      countryId,
      countryName,
      registerMobileNo,
      registerMobileNoErr,
      companyName,
      companyId,
      fasciaName,
      address1,
      address2,
      cityId,
      cityName,
      stateId,
      stateName,
      pinCode,
      inchargeFirstName,
      designation,
      registerAlternateMobileNo,
      registerTelephoneNo,
      registerTelephoneNo1,
      registerTelefaxNo,
      primaryEmailId,
      secondaryEmailId,
      website,
      nameOfOwner,
      lastName,
      ownerMobileNo,
      ownerEmailId,
      countryNamePreRegisterMobileNo,
      countryNamePreRegisterAlternateMobileNo,
      countryNamePreOwnerMobileNo,
      countryNamePreInvoiceregisterMobileNo,
      countryNamePreInvoiceregisterAlternateMobileNo,
      registerTelephoneNoPre,
      registerTelephoneNo1Pre,

      invoiceregisterTelephoneNoPre,
      invoiceregisterTelephoneNo1Pre,

      title_inchargeFirstName,
      title_name_Of_CEO_MD_President_Owner,
      title_InvPersonIncharge,

      other_title_InvPersonIncharge,
      other_title_name_Of_CEO_MD_President_Owner,
      other_title_inchargeFirstName,
      invoicecountryName,
      companyNameErr,
      fasciaNameErr,
      address1Err,
      cityNameErr,
      stateNameErr,
      pinCodeErr,
      countryNameErr,
      inchargeFirstNameErr,
      designationErr,
      registerAlternateMobileNoErr,
      registerTelephoneNoErr,
      registerTelefaxNoErr,
      primaryEmailIdErr,
      secondaryEmailIdErr,
      websiteErr,
      nameOfOwnerErr,

      lastNameErr,
      ownerMobileNoErr,
      ownerEmailIdErr,

      invoicecompanyName,
      invoicecompanyId,
      invoicefasciaName,
      invoiceaddress1,
      invoiceaddress2,
      invoicecityId,
      invoicecityName,
      invoicestateId,
      invoicestateName,
      invoicepinCode,
      invoiceinchargeFirstName,
      invoicedesignation,
      invoiceregisterMobileNo,
      invoiceregisterAlternateMobileNo,
      invoiceregisterTelephoneNo,
      invoiceregisterTelephoneNo1,
      invoiceregisterTelefaxNo,
      invoiceprimaryEmailId,
      invoicesecondaryEmailId,
      invoicewebsite,
      invoicenameOfOwner,
      invoicelastName,
      invoiceownerMobileNo,
      invoiceownerEmailId,

      invoicecompanyNameErr,
      invoicecompanyIdErr,
      invoicefasciaNameErr,
      invoiceaddress1Err,
      invoiceaddress2Err,
      invoicecityIdErr,
      invoicecityNameErr,
      invoicestateIdErr,
      invoicestateNameErr,
      invoicepinCodeErr,
      invoiceinchargeFirstNameErr,
      invoicedesignationErr,
      invoiceregisterMobileNoErr,
      invoiceregisterAlternateMobileNoErr,
      invoiceregisterTelephoneNoErr,
      invoiceregisterTelefaxNoErr,
      invoiceprimaryEmailIdErr,
      invoicesecondaryEmailIdErr,
      invoicewebsiteErr,
      invoicenameOfOwnerErr,
      invoicelastNameErr,
      invoiceownerMobileNoErr,
      invoiceownerEmailIdErr,

      compInfoNameOnPanCard,
      compInfoPanNo,
      compInfoTAN,
      compInfoGstNo,
      compInfoRegistrationNo,
      compInfoIecCode,
      bankDtlAccountName,
      bankDtlAccountNo,
      bankDtlAccountType,
      bankDtlBankName,
      bankDtlBranchName,
      bankDtlIfscCode,

      compInfoNameOnPanCardErr,
      compInfoPanNoErr,
      compInfoTANErr,
      compInfoGstNoErr,
      compInfoRegistrationNoErr,
      compInfoIecCodeErr,
      bankDtlAccountNameErr,
      bankDtlAccountNoErr,
      bankDtlAccountTypeErr,
      bankDtlBankNameErr,
      bankDtlBranchNameErr,
      bankDtlIfscCodeErr,

      formFile,
      trcFile,
      declarationFile,
      isCountryIndia,
      tempCityList,
      isDisplayCityList,
      isDisplayStateList,
      isDisplayPincodeList,
      _pincodeList,
      isDisplayInvCityList,
      isDisplayInvStateList,
      isDisplayInvPincodeList,
      invLastNamePersonIncharge,
      lastNamePersonIncharge,
      invLastNamePersonInchargeErr,
      lastNamePersonInchargeErr,
      bankDtlAccountTypeOthers,

      isOther_title_name_Of_CEO_MD_President_Owner,
      isOther_title_InvPersonIncharge,
      isOther_title_inchargeFirstName,
      isSelfPaid,
      isDataLoad,
    } = this.state;

    const toInputUppercase = (e) => {
      e.target.value = ("" + e.target.value).toUpperCase();
    };

    return (
      <>
        {isLoading ? <Loader></Loader> : null}
        <ToastContainer />
        <>
          {/* PAGE */}
          <div className="page">
            <div className="page-main">
              {isDataLoad ? <Header /> : null}

              {/*app-content open*/}
              <div className="main-content app-content mt-0">
                <div className="side-app">
                  {/* <div className="main-container form-start container-fluid p-0">
                                        {is_update && <>
                                            <div className="row ">
                                                <div className="step-name">
                                                    <p>Exhibitor Registration</p>
                                                </div>
                                            </div>
                                            <StepHeader />
                                        </>}
                                    </div> */}
                  {/* <StepHeader /> */}
                  <div className="page-height">
                    <div className="main-container container-fluid">
                      <div className="row p-1">
                        <div className="form-row">
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom03">
                              Company Name<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                              required=""
                              value={companyName}
                              maxLength={500}
                              onChange={(e) =>
                                this.setState({
                                  companyName: e.target.value,
                                  companyNameErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {companyNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">
                              Fascia Name (Applicable for shell stall only)
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={24}
                              required=""
                              value={fasciaName}
                              onChange={(e) =>
                                this.setState({
                                  fasciaName: e.target.value,
                                  fasciaNameErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {fasciaNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">
                              Address Line 1 <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={200}
                              required=""
                              value={address1}
                              onChange={(e) =>
                                this.setState({
                                  address1: e.target.value,
                                  address1Err: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {address1Err}
                              </span>
                            }
                          </div>
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">
                              Address Line 2{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={200}
                              value={address2}
                              onChange={(e) =>
                                this.setState({ address2: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              City<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required=""
                              value={cityName}
                              onChange={(e) => this.onCitySearch(e)}
                            />
                            {isDisplayCityList && this.renderCityList()}
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {cityNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              {sessionStorage.getItem("sessionContryName") ==
                                "India"
                                ? "State"
                                : "State/Province"}
                              {sessionStorage.getItem("sessionContryName") ==
                                "India " ? (
                                <span className="text-red">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required=""
                              value={stateName}
                              onChange={(e) => this.onStateSearch(e)}
                            />
                            {isDisplayStateList && this.renderStateList()}
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {stateNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              {sessionStorage.getItem("sessionContryName") ==
                                "India "
                                ? "Pincode"
                                : "Zipcode"}
                              <span className="text-red">*</span>
                            </label>
                            {sessionStorage.getItem("sessionContryName") ==
                              "India " && (
                                <input
                                  type="number"
                                  className="form-control"
                                  maxLength={6}
                                  // onKeyPress={this.onlyNumbers}
                                  required=""
                                  value={pinCode}
                                  onChange={(e) => this.onPincodeSearch(e)}
                                // onChange={this.changeUrl}
                                />
                              )}
                            {sessionStorage.getItem("sessionContryName") !=
                              "India " && (
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={10}
                                  // onKeyPress={this.onlyNumbers}
                                  required=""
                                  value={pinCode}
                                  onChange={(e) => this.onPincodeSearch(e)}
                                  onInput={toInputUppercase}
                                // onChange={this.changeUrl}
                                />
                              )}
                            {isDisplayPincodeList && this.renderPincodeList()}
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {pinCodeErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Country<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={countryName}
                            // onChange={e => this.setState({ countryName: e.target.value , countryNameErr : '' })}
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {countryNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3">
                            <label>
                              First Name of Person Incharge
                              <span className="text-red">*</span>
                            </label>
                            {isOther_title_inchargeFirstName && (
                              <input
                                type="text"
                                className="form-control-1 other-fil"
                                value={other_title_inchargeFirstName}
                                onChange={(e) =>
                                  this.setState({
                                    other_title_inchargeFirstName:
                                      e.target.value,
                                  })
                                }
                                placeholder={"Other"}
                                style={{
                                  // position: "absolute",
                                  paddingLeft: "5px",
                                }}
                              />
                            )}

                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              required=""
                              defaultValue=""
                              style={{
                                position: "relative",
                                paddingLeft: "55px",
                              }}
                              value={inchargeFirstName}
                              onChange={(e) =>
                                this.setState({
                                  inchargeFirstName: e.target.value,
                                  inchargeFirstNameErr: "",
                                })
                              }
                            />
                            <div className="col-xl-1 mb-3">
                              <div
                                className="form-group"
                                style={{
                                  width: "45px",
                                  position: "absolute",
                                  top: "-59px",
                                  left: "0px",
                                }}
                              >
                                <label />
                                <select
                                  className="form-control-1 select2 select-hidden-accessible"
                                  id="roledb"
                                  style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderTop: "none",
                                    borderBottom: "none",
                                    marginTop: 0,
                                    textAlign: "left",
                                  }}
                                  value={title_inchargeFirstName}
                                  onChange={(e) =>
                                    this.setState({
                                      title_inchargeFirstName: e.target.value,
                                      isOther_title_inchargeFirstName:
                                        e.target.value == "Other" && true,
                                    })
                                  }
                                >
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="DR">DR</option>
                                  <option value="Prof">Prof</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                            </div>
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {inchargeFirstNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3">
                            <label>
                              Last Name of Person Incharge
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              required=""
                              defaultValue=""
                              value={lastNamePersonIncharge}
                              onChange={(e) =>
                                this.setState({
                                  lastNamePersonIncharge: e.target.value,
                                  lastNamePersonInchargeErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {lastNamePersonInchargeErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Designation <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              maxLength={100}
                              className="form-control"
                              value={designation}
                              onChange={(e) =>
                                this.setState({
                                  designation: e.target.value,
                                  designationErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {designationErr}
                              </span>
                            }
                          </div>
                          <div
                            className="col-xl-3 mb-1"
                            style={{ position: "relative" }}
                          >
                            <label htmlFor="validationCustom01">
                              Mobile No<span className="text-red">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={registerMobileNo}
                              maxLength={
                                sessionStorage.getItem("countryId") === "43"
                                  ? 11
                                  : 10
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              onChange={(e) =>
                                this.setState({
                                  registerMobileNo: e.target.value,
                                  registerMobileNoErr: "",
                                })
                              }
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              placeholder="ISD"
                              className="form-control-1"
                              value={countryNamePreRegisterMobileNo}
                              onChange={(e) =>
                                this.setState({
                                  countryNamePreRegisterMobileNo:
                                    e.target.value,
                                })
                              }
                              style={{
                                width: "45px",
                                position: "absolute",
                                top: "23px",
                                paddingLeft: "5px",
                              }}
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {registerMobileNoErr}
                              </span>
                            }
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Alternate Mobile No{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={registerAlternateMobileNo}
                              maxLength={
                                sessionStorage.getItem("countryId") === "43"
                                  ? 11
                                  : 10
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              onChange={(e) =>
                                this.setState({
                                  registerAlternateMobileNo: e.target.value,
                                })
                              }
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              placeholder="ISD"
                              value={countryNamePreRegisterAlternateMobileNo}
                              onChange={(e) =>
                                this.setState({
                                  countryNamePreRegisterAlternateMobileNo:
                                    e.target.value,
                                })
                              }
                              // placeholder={+91}
                              style={{
                                width: "45px",
                                position: "absolute",
                                top: "26px",
                                paddingLeft: "5px",
                              }}
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Telephone No
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={registerTelephoneNo}
                              maxLength={"10"}
                              onChange={(e) =>
                                this.setState({
                                  registerTelephoneNo: e.target.value,
                                })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              placeholder="STD"
                              value={registerTelephoneNoPre}
                              onChange={(e) =>
                                this.setState({
                                  registerTelephoneNoPre: e.target.value,
                                })
                              }
                              style={{
                                width: "50px",
                                position: "absolute",
                                top: "26px",
                                padding: "6px !important",
                                paddingLeft: "5px",
                              }}
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Telephone No 1
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={registerTelephoneNo1}
                              maxLength={"10"}
                              onChange={(e) =>
                                this.setState({
                                  registerTelephoneNo1: e.target.value,
                                })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              placeholder="STD"
                              value={registerTelephoneNo1Pre}
                              onChange={(e) =>
                                this.setState({
                                  registerTelephoneNo1Pre: e.target.value,
                                })
                              }
                              style={{
                                width: 50,
                                position: "absolute",
                                top: 26,
                                paddingLeft: 5,
                                padding: "6px !important",
                              }}
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">Telefax</label>
                            <input
                              type="text"
                              className="form-control"
                              value={registerTelefaxNo}
                              maxLength={50}
                              onChange={(e) =>
                                this.setState({
                                  registerTelefaxNo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Primary Email (applicable only one email)<span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              maxLength={100}
                              className="form-control"
                              value={primaryEmailId}
                              onChange={(e) => this.onChangePrimaryEmail(e.target.value, 1)}
                            // onBlur={e => primaryEmailId != '' && this.emailFocusOut('fromprimaryemail')}
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {primaryEmailIdErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Secondary Email{" "}
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              maxLength={100}
                              value={secondaryEmailId}
                              onChange={(e) =>
                                this.setState({
                                  secondaryEmailId: e.target.value,
                                })
                              }
                            // onBlur={e => secondaryEmailId != '' && this.emailFocusOut('fromsecondaryemail')}
                            />
                          </div>
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">Website</label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              value={website}
                              onChange={(e) =>
                                this.setState({ website: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3">
                            <label>
                              First Name of CEO/MD/President/Owner
                              <span className="text-red">*</span>
                            </label>
                            {isOther_title_name_Of_CEO_MD_President_Owner && (
                              <input
                                type="text"
                                className="form-control-1 other-fil"
                                placeholder={"Other"}
                                value={
                                  other_title_name_Of_CEO_MD_President_Owner
                                }
                                onChange={(e) =>
                                  this.setState({
                                    other_title_name_Of_CEO_MD_President_Owner:
                                      e.target.value,
                                  })
                                }
                                style={{
                                  // position: "absolute",
                                  paddingLeft: "5px",
                                }}
                              />
                            )}

                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              style={{
                                position: "relative",
                                paddingLeft: "55px",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  nameOfOwner: e.target.value,
                                  nameOfOwnerErr: "",
                                })
                              }
                              value={nameOfOwner}
                            />
                            <div className="col-xl-1 mb-3">
                              <div
                                className="form-group"
                                style={{
                                  width: "45px",
                                  position: "absolute",
                                  top: "-57px",
                                  left: "0px",
                                }}
                              >
                                <label />
                                <select
                                  className="form-control-1 select2 select-hidden-accessible"
                                  id="roledb"
                                  style={{
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    borderTop: "none",
                                    borderBottom: "none",
                                    marginTop: "-2px",
                                    textAlign: "left",
                                  }}
                                  value={title_name_Of_CEO_MD_President_Owner}
                                  onChange={(e) =>
                                    this.setState({
                                      title_name_Of_CEO_MD_President_Owner:
                                        e.target.value,
                                      isOther_title_name_Of_CEO_MD_President_Owner:
                                        e.target.value == "Other" && true,
                                    })
                                  }
                                >
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="DR">DR</option>
                                  <option value="Prof">Prof</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                            </div>
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {nameOfOwnerErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Last Name of CEO/MD/President/Owner
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={lastName}
                              maxLength={100}
                              onChange={(e) =>
                                this.setState({ lastName: e.target.value })
                              }
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Owner Designation
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ownerDesignation}
                              maxLength={100}
                              onChange={(e) =>
                                this.setState({
                                  ownerDesignation: e.target.value, ownerDesignationErr: ''
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {this.state.ownerDesignationErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Mobile No
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={ownerMobileNo}
                              maxLength={
                                sessionStorage.getItem("countryId") === "43"
                                  ? 11
                                  : 10
                              }
                              onChange={(e) =>
                                this.setState({ ownerMobileNo: e.target.value })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              placeholder="STD"
                              className="form-control-1"
                              value={countryNamePreOwnerMobileNo}
                              onChange={(e) =>
                                this.setState({
                                  countryNamePreOwnerMobileNo: e.target.value,
                                })
                              }
                              // placeholder={+91}
                              style={{
                                width: "45px",
                                position: "absolute",
                                top: "25px",
                                left: "7px",
                                paddingLeft: "14px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Email<span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              maxLength={100}
                              value={ownerEmailId}
                              onChange={(e) =>
                                this.setState({
                                  ownerEmailId: e.target.value,
                                  ownerEmailIdErr: "",
                                })
                              }
                            // onBlur={e => ownerEmailId != '' && this.emailFocusOut('fromowneremail')}
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {ownerEmailIdErr}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  Invoice Start Here Here */}
                    <div className=" bg-section">
                      <div className="pt-2 pb-0 p-3">
                        {/* Invoice Start Here ------------------------------------------------------------------*/}
                        <h6 style={{ float: "left" }}>Invoice Details </h6>
                        {!isSelfPaid ? (
                          <label className="d-flex same-info w-auto">
                            <label className="d-flex">
                              <input
                                type="radio"
                                name="correspondenceDetails"
                                value={isCorrespondenceDetails}
                                checked={isCorrespondenceDetails}
                                onClick={(e) =>
                                  this.onCorrespondenceDetailsClick()
                                }
                              />
                              Same as Correspondence Details
                            </label>
                          </label>
                        ) : null}
                      </div>
                      <br></br>
                      <div className="pt-2 pb-0 p-3">
                        <div className="form-row mt-4">
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom03">
                              Company Name<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                              maxLength={500}
                              required=""
                              disabled={isCorrespondenceDetails}
                              value={invoicecompanyName}
                              onChange={(e) =>
                                this.setState({
                                  invoicecompanyName: e.target.value,
                                  invoicecompanyNameErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoicecompanyNameErr}
                              </span>
                            }
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">
                              Address Line 1 <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={200}
                              required=""
                              disabled={isCorrespondenceDetails}
                              value={invoiceaddress1}
                              onChange={(e) =>
                                this.setState({
                                  invoiceaddress1: e.target.value,
                                  invoiceaddress1Err: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoiceaddress1Err}
                              </span>
                            }
                          </div>
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">
                              Address Line 2{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={200}
                              disabled={isCorrespondenceDetails}
                              value={invoiceaddress2}
                              onChange={(e) =>
                                this.setState({
                                  invoiceaddress2: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              City<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={isCorrespondenceDetails}
                              required=""
                              value={invoicecityName}
                              onChange={(e) => this.onInvCitySearch(e)}
                            />
                            {isDisplayInvCityList && this.renderInvCityList()}
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoicecityNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              {sessionStorage.getItem("sessionContryName") ==
                                "India"
                                ? "State"
                                : "State/Province"}
                              {sessionStorage.getItem("sessionContryName") ==
                                "India" ? (
                                <span className="text-red">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={isCorrespondenceDetails}
                              required=""
                              value={invoicestateName}
                              onChange={(e) => this.onInvStateSearch(e)}
                            />
                            {isDisplayInvStateList && this.renderInvStateList()}
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoicestateNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              {sessionStorage.getItem("sessionContryName") ==
                                "India "
                                ? "Pincode"
                                : "Zipcode"}
                              <span className="text-red">*</span>
                            </label>
                            {sessionStorage.getItem("sessionContryName") ==
                              "India " && (
                                <input
                                  type="number"
                                  className="form-control"
                                  maxLength={6}
                                  // onKeyPress={this.onlyNumbers}
                                  required=""
                                  disabled={isCorrespondenceDetails}
                                  value={invoicepinCode}
                                  onChange={(e) => this.onInvPincodeSearch(e)}
                                // onChange={this.changeUrl}
                                />
                              )}
                            {sessionStorage.getItem("sessionContryName") !=
                              "India " && (
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={10}
                                  // onKeyPress={this.onlyNumbers}
                                  required=""
                                  value={invoicepinCode}
                                  disabled={isCorrespondenceDetails}
                                  onChange={(e) => this.onInvPincodeSearch(e)}
                                  onInput={toInputUppercase}
                                // onChange={this.changeUrl}
                                />
                              )}
                            {isDisplayInvPincodeList &&
                              this.renderInvPincodeList()}
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoicepinCodeErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Country<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={invoicecountryName}
                              onChange={(e) =>
                                this.setState({
                                  invoicecountryName: e.target.value,
                                  countryNameErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {countryNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3">
                            <label>
                              First Name of Person Incharge
                              <span className="text-red">*</span>
                            </label>
                            {isOther_title_InvPersonIncharge && (
                              <input
                                type="text"
                                className="form-control-1 other-fil"
                                value={other_title_InvPersonIncharge}
                                onChange={(e) =>
                                  this.setState({
                                    other_title_InvPersonIncharge:
                                      e.target.value,
                                  })
                                }
                                disabled={isCorrespondenceDetails}
                                placeholder={"Other"}
                                style={{
                                  // position: "absolute",

                                  paddingLeft: "5px",
                                }}
                              />
                            )}

                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              required=""
                              style={{
                                position: "relative",
                                paddingLeft: "55px",
                              }}
                              value={invoiceinchargeFirstName}
                              disabled={isCorrespondenceDetails}
                              onChange={(e) =>
                                this.setState({
                                  invoiceinchargeFirstName: e.target.value,
                                  invoiceinchargeFirstNameErr: "",
                                })
                              }
                            />
                            <div className="col-xl-1 mb-3">
                              <div
                                className="form-group"
                                value={title_InvPersonIncharge}
                                onChange={(e) =>
                                  this.setState({
                                    title_InvPersonIncharge: e.target.value,
                                  })
                                }
                                disabled={isCorrespondenceDetails}
                                style={{
                                  width: "45px",
                                  position: "absolute",
                                  top: "-59px",
                                  left: "0px",
                                }}
                              >
                                <label />
                                <select
                                  className="form-control-1 select2 select-hidden-accessible"
                                  id="roledb"
                                  style={{
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    borderTop: "none",
                                    borderBottom: "none",
                                    marginTop: "0px",
                                    textAlign: "left",
                                  }}
                                  value={title_InvPersonIncharge}
                                  disabled={isCorrespondenceDetails}
                                  onChange={(e) =>
                                    this.setState({
                                      title_InvPersonIncharge: e.target.value,
                                      isOther_title_InvPersonIncharge:
                                        e.target.value == "Other" && true,
                                    })
                                  }
                                >
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="DR">DR</option>
                                  <option value="Prof">Prof</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                            </div>
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoiceinchargeFirstNameErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3">
                            <label>
                              Last Name of Person Incharge
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              required=""
                              disabled={isCorrespondenceDetails}
                              value={invLastNamePersonIncharge}
                              onChange={(e) =>
                                this.setState({
                                  invLastNamePersonIncharge: e.target.value,
                                  invLastNamePersonInchargeErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invLastNamePersonInchargeErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Designation <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              disabled={isCorrespondenceDetails}
                              value={invoicedesignation}
                              onChange={(e) =>
                                this.setState({
                                  invoicedesignation: e.target.value,
                                  invoicedesignationErr: "",
                                })
                              }
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoicedesignationErr}
                              </span>
                            }
                          </div>
                          <div
                            className="col-xl-3 mb-1"
                            style={{ position: "relative" }}
                          >
                            <label htmlFor="validationCustom01">
                              Mobile No<span className="text-red">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={isCorrespondenceDetails}
                              maxLength={
                                sessionStorage.getItem("countryId") === "43"
                                  ? 11
                                  : 10
                              }
                              value={invoiceregisterMobileNo}
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterMobileNo: e.target.value,
                                  invoiceregisterMobileNoErr: "",
                                })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              placeholder="ISD"
                              value={countryNamePreInvoiceregisterMobileNo}
                              disabled={isCorrespondenceDetails}
                              onChange={(e) =>
                                this.setState({
                                  countryNamePreInvoiceregisterMobileNo:
                                    e.target.value,
                                })
                              }
                              // placeholder={+91}
                              style={{
                                width: "45px",
                                position: "absolute",
                                top: "25px",
                                paddingLeft: "5px",
                              }}
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoiceregisterMobileNoErr}
                              </span>
                            }
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Alternate Mobile No{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={isCorrespondenceDetails}
                              // disabled={isCorrespondenceDetails}
                              value={invoiceregisterAlternateMobileNo}
                              maxLength={
                                sessionStorage.getItem("countryId") === "43"
                                  ? 11
                                  : 10
                              }
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterAlternateMobileNo:
                                    e.target.value,
                                })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              disabled={isCorrespondenceDetails}
                              placeholder="ISD"
                              value={
                                countryNamePreInvoiceregisterAlternateMobileNo
                              }
                              onChange={(e) =>
                                this.setState({
                                  countryNamePreInvoiceregisterAlternateMobileNo:
                                    e.target.value,
                                })
                              }
                              // placeholder={+91}
                              style={{
                                width: "45px",
                                position: "absolute",
                                top: "23px",
                                paddingLeft: "5px",
                              }}
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Telephone No
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={isCorrespondenceDetails}
                              // value={invoiceregisterTelephoneNo} maxLength={'10'}
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterTelephoneNo: e.target.value,
                                })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              disabled={isCorrespondenceDetails}
                              placeholder="STD"
                              value={invoiceregisterTelephoneNoPre}
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterTelephoneNoPre: e.target.value,
                                })
                              }
                              style={{
                                width: "50px",
                                position: "absolute",
                                top: "23px",
                                paddingLeft: "5px",
                              }}
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Telephone No 1
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={isCorrespondenceDetails}
                              // value={invoiceregisterTelephoneNo1} maxLength={'10'}
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterTelephoneNo1: e.target.value,
                                })
                              }
                              onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                              size={"10"}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 || e.keyCode === 190) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              onKeyPress={(e) => this.preventMinus(e)}
                              style={{ paddingLeft: "50px" }}
                            />
                            <input
                              type="text"
                              className="form-control-1"
                              disabled={isCorrespondenceDetails}
                              placeholder="STD"
                              value={invoiceregisterTelephoneNo1Pre}
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterTelephoneNo1Pre:
                                    e.target.value,
                                })
                              }
                              style={{
                                width: 50,
                                position: "absolute",
                                top: 26,
                                paddingLeft: 5,
                              }}
                            />
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">Telefax</label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={50}
                              disabled={isCorrespondenceDetails}
                              value={invoiceregisterTelefaxNo}
                              onChange={(e) =>
                                this.setState({
                                  invoiceregisterTelefaxNo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Primary Email <span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              maxLength={100}
                              disabled={isCorrespondenceDetails}
                              value={invoiceprimaryEmailId}
                              onChange={(e) => this.onChangePrimaryEmail(e.target.value, 2)}
                            // onBlur={e => invoiceprimaryEmailId != '' && this.invemailFocusOut('invoiceprimaryEmailId')}
                            />
                            {
                              <span className="text-danger text-start text-left d-block mt-1">
                                {invoiceprimaryEmailIdErr}
                              </span>
                            }
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Secondary Email{" "}
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              maxLength={100}
                              disabled={isCorrespondenceDetails}
                              value={invoicesecondaryEmailId}
                              onChange={(e) =>
                                this.setState({
                                  invoicesecondaryEmailId: e.target.value,
                                })
                              }
                            // onBlur={e => invoicesecondaryEmailId != '' && this.invemailFocusOut('invoicesecondaryEmailId')}
                            />
                          </div>
                          <div className="col-xl-6 mb-1">
                            <label htmlFor="validationCustom01">Website</label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={100}
                              disabled={isCorrespondenceDetails}
                              value={invoicewebsite}
                              onChange={(e) =>
                                this.setState({
                                  invoicewebsite: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Invoice End Here ------------------------------------------------------------------*/}
                    {/* COMPANY INFORMATION Start Here */}
                    {isCountryIndia || sessionStorage.getItem("sessionContryName") ==
                      "India " ? (
                      <div className="main-container  pt-3 pb-3 p-3">
                        <h6>
                          {" "}
                          <strong>Company Information</strong>
                        </h6>
                        <div className="form-row mt-2">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Name On Pan Card{" "}
                              {isCountryIndia && (
                                <span className="text-red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              maxLength={100}
                              className="form-control"
                              value={compInfoNameOnPanCard}
                              onChange={(e) =>
                                this.setState({
                                  compInfoNameOnPanCard: e.target.value,
                                  compInfoNameOnPanCardErr: "",
                                })
                              }
                            />
                            {isCountryIndia && (
                              <span className="text-danger text-start text-left d-block mt-1">
                                {compInfoNameOnPanCardErr}
                              </span>
                            )}
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              {" "}
                              PAN No{" "}
                              {isCountryIndia && (
                                <span className="text-red">*</span>
                              )}
                            </label>
                            {console.log("isCountryIndia-->", isCountryIndia)}
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              value={compInfoPanNo}
                              onChange={(e) => {
                                const errMsg = panNoValidator(e.target.value)
                                this.setState({
                                  compInfoPanNo: e.target.value,
                                })
                                if (errMsg != '') {
                                  this.setState({
                                    compInfoPanNoErr: errMsg,
                                  })
                                } else {
                                  this.setState({
                                    compInfoPanNoErr: "",
                                  })
                                }
                              }
                              }
                              onInput={toInputUppercase}
                            />
                            {isCountryIndia && (
                              <span className="text-danger text-start text-left d-block mt-1">
                                {compInfoPanNoErr}
                              </span>
                            )}
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              TAN{" "}
                              {isCountryIndia && (
                                <span className="text-red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              value={compInfoTAN}
                              onChange={(e) => {
                                const errMsg = tanNoValidator(e.target.value)
                                this.setState({
                                  compInfoTAN: e.target.value,
                                })
                                if (errMsg != '') {
                                  this.setState({
                                    compInfoTANErr: errMsg,
                                  })
                                } else {
                                  this.setState({
                                    compInfoTANErr: "",
                                  })
                                }
                              }
                              }
                              onInput={toInputUppercase}
                            />
                            {isCountryIndia && (
                              <span className="text-danger text-start text-left d-block mt-1">
                                {compInfoTANErr}
                              </span>
                            )}
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              GST No{" "}
                              {isCountryIndia && (
                                <span className="text-red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              value={compInfoGstNo}
                              onChange={(e) =>
                                this.onGSTchange(e.target.value)
                              }
                              onInput={toInputUppercase}
                            />
                            {isCountryIndia && (
                              <span className="text-danger text-start text-left d-block mt-1">
                                {compInfoGstNoErr}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-row ">
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              Company Registration No{" "}
                              {isCountryIndia && (
                                <span className="text-red"></span>
                              )}
                            </label>
                            <input
                              type="text"
                              maxLength={21}
                              className="form-control"
                              value={compInfoRegistrationNo}
                              onChange={(e) =>
                                this.setState({
                                  compInfoRegistrationNo: e.target.value,
                                  compInfoRegistrationNoErr: "",
                                })
                              }
                              onInput={toInputUppercase}
                            />
                            {isCountryIndia && (
                              <span className="text-danger text-start text-left d-block mt-1">
                                {compInfoRegistrationNoErr}
                              </span>
                            )}
                          </div>
                          <div className="col-xl-3 mb-1">
                            <label htmlFor="validationCustom01">
                              {" "}
                              IEC (Import Export Code)
                            </label>
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              value={compInfoIecCode}
                              onChange={(e) =>
                                this.setState({
                                  compInfoIecCode: e.target.value,
                                })
                              }
                              onInput={toInputUppercase}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* COMPANY INFORMATION End Here ------------------------------------------------------------------*/}
                    {sessionStorage.getItem("sessionContryName") ==
                      "India " && (
                        <div className="main-container bg-section">
                          <div className="container-fluid pt-2 pb-0 p-3">
                            <h6> Bank Details </h6>
                            <div className="form-row mt-2">
                              <div className="col-xl-3 mb-1">
                                <label htmlFor="validationCustom01">
                                  Account Name
                                </label>
                                <input
                                  type="text"
                                  maxLength={100}
                                  className="form-control"
                                  value={bankDtlAccountName}
                                  onChange={(e) =>
                                    this.setState({
                                      bankDtlAccountName: e.target.value,
                                      bankDtlAccountNameErr: "",
                                    })
                                  }
                                />
                                {
                                  <span className="text-danger text-start text-left d-block mt-1">
                                    {bankDtlAccountNameErr}
                                  </span>
                                }
                              </div>
                              <div className="col-xl-3 mb-1">
                                <label htmlFor="validationCustom01">
                                  {" "}
                                  Account No
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  maxLength={"30"}
                                  onInput={(e) => this.maxLengthCheck(e, 30)}
                                  value={bankDtlAccountNo}
                                  onChange={(e) =>
                                    this.setState({
                                      bankDtlAccountNo: e.target.value,
                                      bankDtlAccountNoErr: "",
                                    })
                                  }
                                />
                                {
                                  <span className="text-danger text-start text-left d-block mt-1">
                                    {bankDtlAccountNoErr}
                                  </span>
                                }
                              </div>
                              <div className="col-xl-3 mb-1">
                                <label htmlFor="validationCustom01">
                                  Account Type
                                </label>
                                <select
                                  className="form-control-1 select2 select-hidden-accessible text-left"
                                  id="roledb"
                                  style={{ textAlign: "left" }}
                                  value={bankDtlAccountType}
                                  onChange={(e) =>
                                    this.setState({
                                      bankDtlAccountType: e.target.value,
                                    })
                                  }
                                >
                                  <option>Select</option>
                                  <option value={"Saving account"}>
                                    Saving account
                                  </option>
                                  <option value={"Current account"}>
                                    Current account
                                  </option>
                                  <option value={"Overdraft account"}>
                                    Overdraft account
                                  </option>
                                  <option value={"Others"}>Others</option>
                                </select>
                                {bankDtlAccountType == "Others" ? (
                                  <input
                                    type="text"
                                    maxLength={20}
                                    className="form-control acount-type-oth"
                                    value={bankDtlAccountTypeOthers}
                                    onChange={(e) =>
                                      this.setState({
                                        bankDtlAccountTypeOthers: e.target.value,
                                      })
                                    }
                                  />
                                ) : null}
                              </div>
                            </div>
                            <div className="form-row ">
                              <div className="col-xl-3 mb-1">
                                <label htmlFor="validationCustom01">
                                  Bank Name
                                </label>
                                <input
                                  type="text"
                                  maxLength={100}
                                  className="form-control"
                                  value={bankDtlBankName}
                                  onChange={(e) =>
                                    this.setState({
                                      bankDtlBankName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-xl-3 mb-1">
                                <label htmlFor="validationCustom01">
                                  Branch Name
                                </label>
                                <input
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  value={bankDtlBranchName}
                                  onChange={(e) =>
                                    this.setState({
                                      bankDtlBranchName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-xl-3 mb-1">
                                <label htmlFor="validationCustom01">
                                  {" "}
                                  IFSC CODE
                                </label>
                                <input
                                  type="text"
                                  maxLength={11}
                                  className="form-control"
                                  value={bankDtlIfscCode}
                                  onChange={(e) =>
                                    this.setState({
                                      bankDtlIfscCode: e.target.value,
                                      bankDtlIfscCodeErr: "",
                                    })
                                  }
                                  onInput={toInputUppercase}
                                />
                                {
                                  <span className="text-danger text-start text-left d-block mt-1">
                                    {bankDtlIfscCodeErr}
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {/* !isCountryIndia && */}
                    {/* {

                                            sessionStorage.getItem("sessionContryName") != 'India ' &&
                                            <div className="main-container container-fluid pt-2 pb-1 p-3">
                                                <h6 className="">
                                                    <strong>Documents for Foreign Refund (If any)</strong>
                                                </h6>
                                                <p className="mb-1">
                                                    Send below detail along with payment transfer remittance
                                                    details
                                                    <strong>(to show only for Foreign partipcant)</strong>
                                                </p>
                                                <p className="m-0 mb-2">
                                                    <i className="fa fa-angle-right" /> Form 10 F (duly sign &amp;
                                                    stamp)
                                                    <input
                                                        type="file"
                                                        id="formFile"
                                                        name="formFile"
                                                        multiple=""
                                                        style={{ marginLeft: '15px' }}
                                                        value ={formFile}
                                                        onChange={e => this.onFilePick(e, e.target.files, 'formFile')}
                                                    />
                                                    <br />
                                                </p>
                                                <p className="m-0 mb-2">
                                                    <i className="fa fa-angle-right" /> Declaration (duly sign
                                                    &amp; stamp)
                                                    <input type="file" id="declarationFile" name="declarationFile"
                                                        multiple=""
                                                        style={{ marginLeft: '6px' }}
                                                         value ={declarationFile}
                                                        onChange={e => this.onFilePick(e, e.target.files, 'declarationFile')} />
                                                    <br />
                                                </p>
                                                <p className="m-0 mb-2">
                                                    <i className="fa fa-angle-right" /> Tax Resident Certificate(
                                                    TRC)
                                                    <input
                                                        type="file"
                                                        id="trcFile"
                                                        name="trcFile"
                                                        multiple=""
                                                        value ={trcFile}
                                                        style={{ marginLeft: '15px' }}
                                                        onChange={e => this.onFilePick(e, e.target.files, 'trcFile')}
                                                    />
                                                    <br />
                                                </p>
                                            </div>
                                        } */}

                    <div className="main-container container-fluid p-2 pt-0 pb-0">
                      <div className="mt-1 mb-4">
                        {sessionStorage.getItem("sessionExhRegistrationID") ==
                          null && (
                            <button
                              className="btn btn-previous"
                              onClick={(e) => this.onPreviousClick()}
                            >
                              {" "}
                              Previous{" "}
                            </button>
                          )}

                        <button
                          className="btn btn-next-bot mx-2"
                          onClick={(e) => this.onNextClick()}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="bottom-height"></div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default withRouter(
  connect(null, {
    registrationOneAdd_action,
    fill_action,
    registrationOneUpdate_action,
    getregistrationDetails_action,
    getPendingRegDetails_action,
    getAgentDetails_action,
  })(Registration)
);
