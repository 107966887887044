import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getExhAdditionalFurnitureDetailsUrl, insertUpdateExhAdditionalFurnitureUrl, GET_EXH_ADDITIONAL_FURNITURE_PRICES, INSERT_UPDATE_EXH_ADDITIONAL_FURNITURE_PRICES, headers } from "../../../../utils/constants";


export const getExhAdditionalFurnitureDetails_action = (ExihibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
        }

        const res = await axiosGet(getExhAdditionalFurnitureDetailsUrl, params, headers)

        dispatch({
            type: GET_EXH_ADDITIONAL_FURNITURE_PRICES,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateExhAdditionalFurniture_action = (ExihibitionID, ExhAdditionalFurnitureList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ExhAdditionalFurnitureList: ExhAdditionalFurnitureList,
        }

        const res = await axiosPost(insertUpdateExhAdditionalFurnitureUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_EXH_ADDITIONAL_FURNITURE_PRICES,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}