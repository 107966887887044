import { GET_RESERVED_PRIORITY_NO_DETAILS, INSERT_RESERVED_PRIORITY_NO, DELETE_RESERVED_PRIORITY_NO } from "../../../utils/constants";

const initialState = [];

function reservedPriorityNoReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_RESERVED_PRIORITY_NO_DETAILS:
            return [...state, payload];
        case INSERT_RESERVED_PRIORITY_NO:
            return [...state, payload];
        case DELETE_RESERVED_PRIORITY_NO:
            return [...state, payload];

        default:
            return state;
    }
};

export default reservedPriorityNoReducer