import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getReservedPriorityNoDetailsUrl, insertReservedPriorityNoUrl,deleteReservedPriorityNoUrl, INSERT_RESERVED_PRIORITY_NO, GET_RESERVED_PRIORITY_NO_DETAILS, DELETE_RESERVED_PRIORITY_NO, headers } from "../../../../utils/constants";


export const getReservedPriorityNoDetails_action = (ExihibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
        }

        const res = await axiosGet(getReservedPriorityNoDetailsUrl, params, headers)

        dispatch({
            type: GET_RESERVED_PRIORITY_NO_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertReservedPriorityNo_action = (ExihibitionID, ReservedPriorityNoList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ReservedPriorityNoList: ReservedPriorityNoList,
        }

        const res = await axiosPost(insertReservedPriorityNoUrl, params, headers)

        dispatch({
            type: INSERT_RESERVED_PRIORITY_NO,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteReservedPriorityNo_action = (ID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ID: ID,
        }

        const res = await axiosGet(deleteReservedPriorityNoUrl, params, headers)

        dispatch({
            type: DELETE_RESERVED_PRIORITY_NO,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}